import { FC, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ConferenceList from '../../components/admin/ConferenceList';
import PageTitle from '../../components/PageTitle';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';

const AdminConferences: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <PageTitle content={t('admin-conferences.page.title')} />
      <PageSubtitle content={t('admin-conferences.page.subtitle')}>
        <Link to={AuthenticatedRoutesAdmin.NewConference}>
          <button className="btn-sm btn-primary mt-auto">{t('admin-conferences.actions.addConference')}</button>
        </Link>
      </PageSubtitle>
      <PageParagraph content={t('admin-conferences.page.paragraph')} />
      <Container fluid as="section">
        <Row>
          <Col>
            <ConferenceList />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminConferences;
