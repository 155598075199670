import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IFailResponse, IRejectResponse, IServerResponse, ISuccessResponse } from '../../api/api.interface';
import UsersAPI from '../../api/users.api';
import { IProfile } from '../../interfaces/attendee.interface';
import { parseErrorToRejectResponse, parseRejectServerResponseToRejectResponse } from '../../utils/api-reject-parser';

export interface IGetUserThunkPayload {
  id: number;
}

export interface IGetUserFulfilledPayload {
  item: IProfile;
}

export const getUser = createAsyncThunk(
  "user/get",
  async (thunkPayload: IGetUserThunkPayload, thunkAPI) => {
    try {
      const response = await UsersAPI.getUser(thunkPayload);

      const payload = response.data as IServerResponse<IProfile>;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        const successPayload = response.data as ISuccessResponse<IProfile>;

        const item = successPayload.data as IProfile;

        const fulfillValue = { item } as IGetUserFulfilledPayload;

        return thunkAPI.fulfillWithValue(fulfillValue);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGetUserState {
  error: IRejectResponse | null;
  value: IProfile | null;
}

const initialState: IGetUserState = {
  error: null,
  value: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.error = null;
      state.value = null;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetUserFulfilledPayload;

      state.value = payload.item;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.error = payload;
    });
  },
});

export default userSlice.reducer;
