export const mock = {
  documents: [
    {
      id: 1,
      name: "Ordine Del Giorno",
      url: "/assets/documents/odg.pdf",
    },
    {
      id: 2,
      name: "Proventivo ROSSI SNC",
      url: "/assets/documents/preventivo_rossiSNC.pdf",
    }
  ],
  polls: [
    {
      disgiunto: '1',
      titolo: 'rinnovo carica amministratore',
      deleghe: [],
      sospesa: '1',
      utentiDelega: [],
      percentualeVotanti: 0,
      flg_deleghe_visibile: true,
      lunghezzaMassimaTestLibero: 150,
      dirittoVoto: false,
      palese: '1',
      preferenze: [
        {
          tipo_preferenza: 'Standard',
          id_preferenza: '45',
          id_tipo_preferenza: '0',
          testo: 'NO',
          score: 0,
          percentage: 0,
        },
        {
          tipo_preferenza: 'Standard',
          id_preferenza: '43',
          id_tipo_preferenza: '0',
          testo: 'SI',
          score: 0,
          percentage: 0,
        },
      ],
      nrUtentiVotanti: 2,
      haDelegato: false,
      numero_preferenze_max: '1',
      modificabile: false,
      liste: '',
      valore_soglia: '0.0',
      nrUtentiTotali: 4,
      id_tipo_votazione: 0,
      numero_preferenze_min: '1',
      domanda: 'Riconferma attuale amministratore?',
      data_inizio: null,
      prefCountMap: {
        ID_99999: 2,
        ID_0: 2,
      },
      flg_quorum_visibile: false,
      id_votazione: 22,
      nrUtentiAtenuti: 4,
      haVotato: false,
      quorum_abilitato: false,
      inCorso: true,
      data_fine: null,
      gruppi: [
        {
          abilitato: '0',
          nome_gruppo: 'Condominio Azzurro',
          id_gruppo: '17',
        },
        {
          abilitato: '1',
          nome_gruppo: 'Condominio Villa dei Fiori',
          id_gruppo: '2',
        },
      ],
    },
    {
      disgiunto: '1',
      titolo: 'Incarico ad avvocato per vertenza con condominio di fronte',
      deleghe: [],
      sospesa: '1',
      utentiDelega: [],
      percentualeVotanti: 0,
      flg_deleghe_visibile: true,
      lunghezzaMassimaTestLibero: 150,
      dirittoVoto: false,
      palese: '1',
      preferenze: [
        {
          tipo_preferenza: 'Standard',
          id_preferenza: '34',
          id_tipo_preferenza: '0',
          testo: 'NON SAPREI',
        },
        {
          tipo_preferenza: 'Standard',
          id_preferenza: '32',
          id_tipo_preferenza: '0',
          testo: 'NO',
        },
        {
          tipo_preferenza: 'Standard',
          id_preferenza: '30',
          id_tipo_preferenza: '0',
          testo: 'SI',
        },
      ],
      nrUtentiVotanti: 0,
      haDelegato: false,
      numero_preferenze_max: '1',
      modificabile: false,
      liste: '<option value="-1">Nessuna lista selezionata</option>',
      valore_soglia: '0.0',
      nrUtentiTotali: 4,
      id_tipo_votazione: 0,
      numero_preferenze_min: '1',
      domanda: '<p>Il costo è di 13000 euro, faremo una contestazione via TAR</p>',
      data_inizio: null,
      prefCountMap: {
        ID_99999: 3,
        ID_0: 3,
      },
      flg_quorum_visibile: false,
      id_votazione: 13,
      nrUtentiAtenuti: 4,
      haVotato: false,
      quorum_abilitato: false,
      inCorso: false,
      data_fine: null,
      gruppi: [
        {
          abilitato: '0',
          nome_gruppo: 'Condominio Azzurro',
          id_gruppo: '17',
        },
        {
          abilitato: '1',
          nome_gruppo: 'Condominio Villa dei Fiori',
          id_gruppo: '2',
        },
      ],
    },
    {
      disgiunto: '1',
      titolo: 'Manutenzione ascensore',
      deleghe: [],
      sospesa: '1',
      utentiDelega: [],
      percentualeVotanti: 0,
      flg_deleghe_visibile: true,
      lunghezzaMassimaTestLibero: 150,
      dirittoVoto: false,
      palese: '1',
      preferenze: [
        {
          tipo_preferenza: 'Standard',
          id_preferenza: '24',
          id_tipo_preferenza: '0',
          testo: 'NO',
        },
        {
          tipo_preferenza: 'Standard',
          id_preferenza: '22',
          id_tipo_preferenza: '0',
          testo: 'SI',
        },
        {
          tipo_preferenza: 'Scheda Bianca',
          id_preferenza: '27',
          id_tipo_preferenza: '3',
          testo: 'MI ASTENGO',
        },
      ],
      nrUtentiVotanti: 0,
      haDelegato: false,
      numero_preferenze_max: '1',
      modificabile: false,
      liste: '',
      valore_soglia: '0.0',
      nrUtentiTotali: 4,
      id_tipo_votazione: 0,
      numero_preferenze_min: '1',
      domanda: "Nell'anno corrente è necessario cambiare la scatola fusibili. Il costo è 1000 euro.",
      data_inizio: null,
      prefCountMap: {
        ID_99999: 3,
        ID_3: 1,
        ID_0: 2,
      },
      flg_quorum_visibile: false,
      id_votazione: 12,
      nrUtentiAtenuti: 4,
      haVotato: false,
      quorum_abilitato: false,
      inCorso: false,
      data_fine: null,
      gruppi: [
        {
          abilitato: '0',
          nome_gruppo: 'Condominio Azzurro',
          id_gruppo: '17',
        },
        {
          abilitato: '1',
          nome_gruppo: 'Condominio Villa dei Fiori',
          id_gruppo: '2',
        },
      ],
    },
  ],
  groups: [
    {
      id: 17,
      nome: 'Condominio Azzurro',
      descrizione: '',
      totalePeso: 1000,
      presidentId: null,
      secretaryId: null,
      utentiGruppo: [
        {
          id: 4,
          cognome: 'mattia',
          nome: 'operatore',
          peso: 0.500,
          email: 'operatore.mattia@demo.it',
          telefono: null,
        },
        {
          id: 18,
          cognome: 'DUE',
          nome: 'PROPRIETARIO',
          peso: 0.500,
          email: 'proprdue@propr.it',
          telefono: null,
        },
      ],
    },
    {
      id: 2,
      nome: 'Condominio Villa dei Fiori',
      descrizione: '',
      totalePeso: 1000,
      presidentId: 8,
      secretaryId: 130,
      utentiGruppo: [
        {
          id: 8,
          cognome: 'DUE',
          nome: 'PROPRIETARIO',
          peso: 0.101,
          email: 'proprdue@propr.it',
          telefono: null,
        },
        {
          id: 6,
          cognome: 'UNO',
          nome: 'PROPRIETARIO',
          peso: 0.336,
          email: 'provamail@propr.it',
          telefono: null,
        },
        {
          id: 11,
          cognome: 'PROVA',
          nome: 'MATTIA',
          peso: 0.412,
          email: 'mattia.licciardi@gmail.com',
          telefono: null,
        },
        {
          id: 130,
          cognome: 'TRE',
          nome: 'PROPRIETARIO',
          peso: 0.151,
          email: 'proprtre@propr.it',
          telefono: null,
        },
      ],
    },
  ],
};
