import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Conference from '../../components/user/Conference';

const UserConference: FC = () => {
  const { conferenceId } = useParams<{ conferenceId: string }>();

  return (
    <Container fluid as="section">
      <Row>
        <Col>{conferenceId && <Conference conferenceId={conferenceId} />}</Col>
      </Row>
    </Container>
  );
};

export default UserConference;
