import { AxiosError } from 'axios';
import { IFailResponse, IRejectResponse } from '../api/api.interface';

export const parseRejectServerResponseToRejectResponse = (response: IFailResponse): IRejectResponse => {
  const obj: IRejectResponse = {
    success: !!response.status,
    status: response.status,
    error: response.message,
  };

  return obj;
};

export const parseErrorToRejectResponse = (error: AxiosError): IRejectResponse => {
  const obj: IRejectResponse = {
    success: false,
    status: error.request.status,
    error: error.message,
  };

  return obj;
};
