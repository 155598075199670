import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './layout/Layout';
import RouterOutlet from './router';

const App: FC = () => {
  return (
    <BrowserRouter>
      <Layout logo="/assets/logo-light-theme.svg">
        <RouterOutlet />
      </Layout>
    </BrowserRouter>
  );
};

export default App;
