import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { DOMAIN } from '../api/api.config';
import AuthService from './auth.service';

export class AxiosService {
  private static _instance: AxiosService = new AxiosService();

  public static get instance() {
    return this._instance;
  }

  private _axios: AxiosInstance = axios.create(this.config);

  public get axios(): AxiosInstance {
    return this._axios;
  }

  constructor() {
    if (AxiosService._instance) {
      throw new Error("Error: Instantiation failed: Use AxiosService.instance instead of new.");
    }

    AxiosService._instance = this;

    this.LoadState();
  }

  public LoadState(): void {
    this.ClearState();

    const token = AuthService.instance.token;
    if (token) {
      this._axios.defaults.headers.common['X-AUTH-TOKEN'] = token;
      // this._axios.defaults.headers.common['x-auth-token'] = token;
    }
  };

  public ClearState() {
    this._axios = axios.create(this.config);
  }

  private get config(): AxiosRequestConfig {
    return { baseURL: DOMAIN };
  }
};

export default AxiosService;
