import { FC, Fragment } from 'react';
import { Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';

const AdminDashboard: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <PageTitle content={t('admin-dashboard.page.title')} />
      <PageSubtitle content={t('admin-dashboard.page.subtitle')} />
      <PageParagraph content={t('admin-dashboard.page.paragraph')} />
      <Container fluid as="section">
        <Row className="d-flex align-items-stretch row my-5">
          <Col xs={12} md={4} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/astronaut.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle"
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-primary">{t('admin-dashboard.card-manage.title')}</h4>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">
                      {t('admin-dashboard.card-manage.list-item-1.title')}
                    </h6>
                    <p>{t('admin-dashboard.card-manage.list-item-1.paragraph')}</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">
                      {t('admin-dashboard.card-manage.list-item-2.title')}
                    </h6>
                    <p>{t('admin-dashboard.card-manage.list-item-2.paragraph')}</p>
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/rocket.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle"
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-primary">{t('admin-dashboard.card-create.title')}</h4>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">
                      {t('admin-dashboard.card-create.list-item-1.title')}
                    </h6>
                    <p>{t('admin-dashboard.card-create.list-item-1.paragraph')}</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">
                      {t('admin-dashboard.card-manage.list-item-2.title')}
                    </h6>
                    <p>{t('admin-dashboard.card-create.list-item-2.paragraph')}</p>
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} className="my-5">
            <Card className="h-100">
              <Card.Img
                variant="top"
                src="/assets/radar.svg"
                height={128}
                className="position-absolute top-0 start-50 translate-middle"
              />
              <Card.Body className="pt-5">
                <h4 className="mt-5 text-center text-primary">{t('admin-dashboard.card-view.title')}</h4>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">{t('admin-dashboard.card-view.list-item-1.title')}</h6>
                    <p>{t('admin-dashboard.card-view.list-item-1.paragraph')}</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">{t('admin-dashboard.card-view.list-item-2.title')}</h6>
                    <p>{t('admin-dashboard.card-view.list-item-2.paragraph')}</p>
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminDashboard;
