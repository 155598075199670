import { FC, useEffect, useState } from 'react';
import {
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Button
} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { IAttendee } from '../../interfaces/attendee.interface';
import { getUsers } from '../../redux/slices/users.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const UserList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  
  const [searchValue, setSearchValue] = useState('');

  const users: IAttendee[] = useSelector((s: RootState) => s.users.value as IAttendee[]);
  
  const rowActions = (_cell: undefined, _row: IAttendee, _rowIndex: number) => {
    return (
      <div className="d-grid">
      <Button disabled size="sm" 
        onClick={() => { }}
      >
        {t('admin-users.user-list.table.actions.edit')}
      </Button>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'username',
      text: t('admin-users.user-list.table.columns.username'),
    },
    {
      dataField: 'nomeCognome',
      text: t('admin-users.user-list.table.columns.full-name'),
    },
    {
      dataField: 'email',
      text: t('admin-users.user-list.table.columns.email'),
    },
    {
      dataField: 'telefono',
      text: t('admin-users.user-list.table.columns.phone'),
    },
    {
      dataField: "edit",
      text: "",
      formatter: rowActions
    }
  ];

  const options = {
    alwaysShowAllBtns: true
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <Card>
      <Card.Header>
        <Form>
          <Row>
          <Col md={{ span: 3 }}>
            <InputGroup>
              <FormControl
                placeholder={t('admin-users.user-list.table.actions.search')}
                value={searchValue}
                onInput={(e) => setSearchValue(e.currentTarget.value)}
              />
              <InputGroup.Text>
                <Search />
              </InputGroup.Text>
            </InputGroup>
            </Col>
            <Col md={{ offset: 9 }}></Col>
          </Row>
        </Form>
      </Card.Header>
      <Card.Body>
        <BootstrapTable keyField="id" bordered={false} data={users} columns={columns} pagination={paginationFactory(options)} />
      </Card.Body>
    </Card>
  );
};

export default UserList;
