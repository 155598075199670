export enum Routes {
  NotFound = "/not-found",
  Root = "/",
}

export enum PublicRoutes {
  Contact = "/contactus",
}


export enum UnauthenticatedRoutes {
  Register = "/register",
  VerifyAccount = "/verify-account",
  VerifyAccountToken = "/verify-account/:token",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  ResetPasswordToken = "/reset-password/:token",
  Login = "/login",
  Logout = "/logout",
}

export enum AuthenticatedRoutes {
  Me = "/me",
}

export enum AuthenticatedRoutesUser {
  Support = "/support",
  Dashboard = "/dashboard",
  Conferences = "/conferences",
  Conference = "/conferences/:conferenceId",
  ConferenceLive = "/conferences/:conferenceId/live",
  UserPollVote = "/user-poll-vote",
  UserPollResults = "/user-poll-results",
}


export enum AuthenticatedRoutesAdmin {
  Support = "/support",
  Settings = "/admin/settings",
  Dashboard = "/admin/dashboard",
  Community = "/admin/community",
  CommunityGroups = "/admin/community/groups",
  NewGroup = "/admin/community/groups/new",
  CommunityUsers = "/admin/community/users",
  NewUser = "/admin/community/users/new",
  Conferences = "/admin/conferences",
  NewConference = "/admin/conferences/new",
  Conference = "/admin/conferences/:conferenceId",
  ConferenceEdit = "/admin/conferences/:conferenceId/edit",
  ConferenceLive = "/admin/conferences/:conferenceId/live",
  Messages = "/admin/messages",
  Results = "/admin/results",
}
