import { FC, useEffect, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { IGroup } from '../../interfaces/group.interface';
import { getGroups } from '../../redux/slices/groups.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const GroupList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [searchValue, setSearchValue] = useState('');

  const groups: IGroup[] = useSelector((s: RootState) => s.groups.value as IGroup[]);

  const rowActions = (_cell: undefined, _row: IGroup, _rowIndex: number) => {
    return (
      <div className="d-grid">
        <Button
          size="sm" 
          disabled
          onClick={() => {
            //console.debug('rowActions', row);
          }}
        >
          {t('admin-groups.group-list.table.actions.edit')}
        </Button>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'nome',
      text: t('admin-groups.group-list.table.columns.name'),
    },
    {
      dataField: 'descrizione',
      text: t('admin-groups.group-list.table.columns.description'),
    },
    {
      dataField: 'fake',
      text: '',
    },
    {
      dataField: 'edit',
      text: '',
      formatter: rowActions,
    },
  ];

  const options = {
    alwaysShowAllBtns: true,
  };

  useEffect(() => {
    dispatch(getGroups());
  }, [dispatch]);

  return (
    <Card>
      <Card.Header>
        <Form>
          <Row>
            <Col md={{ span: 3 }}>
              <InputGroup>
                <FormControl
                  placeholder={t('admin-groups.group-list.table.actions.search')}
                  value={searchValue}
                  onInput={(e) => setSearchValue(e.currentTarget.value)}
                />
                <InputGroup.Text>
                  <Search />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col md={{ offset: 9 }}></Col>
          </Row>
        </Form>
      </Card.Header>
      <Card.Body>
        <BootstrapTable
          keyField="id"
          bordered={false}
          data={groups}
          columns={columns}
          pagination={paginationFactory(options)}
        />
      </Card.Body>
    </Card>
  );
};

export default GroupList;
