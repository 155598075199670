import { FC, useEffect, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IConference } from '../../interfaces/conference.interface';
import { getConferences } from '../../redux/slices/conferences.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { format } from 'date-fns';

const ConferenceList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');

  const conferences: IConference[] = useSelector((s: RootState) => s.conferences.value as IConference[]);

  const rowActions = (_cell: undefined, row: IConference, _rowIndex: number) => {
    return (
      <div className="d-grid">
        <Button
          size="sm"
          onClick={() => {
            navigate(AuthenticatedRoutesAdmin.ConferenceLive.replace(':conferenceId', row.id_conference.toString()));
          }}
        >
          {t('admin-conferences.conference-list.table.actions.join')}
        </Button>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'title',
      text: t('admin-conferences.conference-list.table.columns.title'),
    },
    {
      dataField: 'description',
      text: t('admin-conferences.conference-list.table.columns.description'),
    },
    {
      dataField: 'start_date',
      text: t('admin-conferences.conference-list.table.columns.date'),
      formatter: (_cell: undefined, row: IConference, _rowIndex: number) => {
        return (
          <div>
            {row && format(new Date(row.start_date), 'dd.MM.yyyy') + '  ' + format(new Date(row.start_date), 'HH:mm')}
          </div>
        );
      },
    },
    {
      dataField: 'actions',
      text: '',
      formatter: rowActions,
    },
  ];

  const options = {
    alwaysShowAllBtns: true,
  };

  useEffect(() => {
    dispatch(getConferences());
  }, [dispatch]);

  return (
    <Card>
      <Card.Header>
        <Form>
          <Row>
            <Col md={{ span: 3 }}>
              <InputGroup>
                <FormControl
                  placeholder={t('admin-conferences.conference-list.table.actions.search')}
                  value={searchValue}
                  onInput={(e) => setSearchValue(e.currentTarget.value)}
                />
                <InputGroup.Text>
                  <Search />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col md={{ offset: 9 }}></Col>
          </Row>
        </Form>
      </Card.Header>
      <Card.Body>
        <BootstrapTable
          keyField="id_conference"
          bordered={false}
          data={conferences}
          columns={columns}
          pagination={paginationFactory(options)}
        />
      </Card.Body>
    </Card>
  );
};

export default ConferenceList;
