import { createSlice } from "@reduxjs/toolkit";
import { IConference } from '../../interfaces/conference.interface';

export interface IUIState {
  sidebar: { expanded: boolean; };
  live: { conference: IConference; } | null;
}

const initialState: IUIState = {
  sidebar: { expanded: false },
  live: null,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.sidebar.expanded = !state.sidebar.expanded;
    },
    openSidebar(state) {
      state.sidebar = { expanded: true };
    },
    closeSidebar(state) {
      state.sidebar = { expanded: false };
    },
    startLiveConference(state, action) {
      state.live = { conference: action.payload };
    },
    stopLiveConference(state) {
      state.live = null;
    },
  },
});

export const {
  toggleSidebar,
  openSidebar,
  closeSidebar,
  startLiveConference,
  stopLiveConference
  ,
} = uiSlice.actions;

export default uiSlice.reducer;
