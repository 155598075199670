import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getConferences = () => {
  return AxiosService.instance.axios.get(ApiEndpoints.ConferenceList);
};

export const ConferencesAPI = {
  getConferences,
};

export default ConferencesAPI;
