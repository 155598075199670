import { FC } from 'react';

const LoginBackground: FC = () => {
  return (
    <section className="position-fixed min-vw-100 min-vh-100 login-background d-none d-lg-block">
      <div className="logo position-relative">
        <img className="position-absolute top-100 start-50 translate-middle-x mt-5" src="/assets/logo-dark-theme.svg" />
      </div>
      <div className="moon position-relative">
        <img className="position-absolute top-0 start-100 translate-middle" src="/assets/moon.svg" />
      </div>
      <div className="pilot position-relative min-vw-100 min-vh-100">
        <img className="position-absolute bottom-0 start-0 h-100" src="/assets/pilot.svg" />
      </div>
    </section>
  );
};

export default LoginBackground;
