import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import {
  AuthenticatedRoutes as ar,
  AuthenticatedRoutesAdmin as aar,
  AuthenticatedRoutesUser as aur,
  Routes as r,
  UnauthenticatedRoutes as ur,
} from './enums/routes.enum';
import { IUser } from './interfaces/user.interface';
import AdminConference from './pages/AdminPages/AdminConference';
import AdminConferenceLive from './pages/AdminPages/AdminConferenceLive';
import AdminConferences from './pages/AdminPages/AdminConferences';
import AdminDashboard from './pages/AdminPages/AdminDashboard';
import AdminGroups from './pages/AdminPages/AdminGroups';
import AdminMessages from './pages/AdminPages/AdminMessages';
import AdminUsers from './pages/AdminPages/AdminUsers';
import NewConference from './pages/AdminPages/NewConference';
import Settings from './pages/AdminPages/Settings';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import NotImplemented from './pages/NotImplemented';
import Profile from './pages/Profile';
import UserConference from './pages/UserPages/UserConference';
import UserConferenceLive from './pages/UserPages/UserConferenceLive';
import UserConferences from './pages/UserPages/UserConferences';
import UserDashboard from './pages/UserPages/UserDashboard';
import UserPollResults from './pages/UserPages/UserPollsResults';
import UserPollVote from './pages/UserPages/UserPollVote';
import { RootState } from './redux/store';
import { AuthenticatedRoute as AuthRoute } from './router/AuthenticatedRoute';
import { AuthenticatedRouteAdmin as AuthAdmin } from './router/AuthenticatedRouteAdmin';
import { AuthenticatedRouteUser as AuthUser } from './router/AuthenticatedRouteUser';
import { UnauthenticatedRoute as NoAuth } from './router/UnauthenticatedRoute';

const RouterOutlet: FC = () => {
  const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value || null);

  return (
    <Routes>
      <Route path={r.NotFound} element={<NoAuth user={user} component={NotFound} />} />
      <Route path={r.Root} element={<NoAuth user={user} component={Login} />} />

      <Route path={ur.Register} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.VerifyAccount} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.VerifyAccountToken} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.ForgotPassword} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.ResetPassword} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.ResetPasswordToken} element={<NoAuth user={user} component={NotImplemented} />} />
      <Route path={ur.Login} element={<NoAuth user={user} component={Login} />} />

      <Route path={ar.Me} element={<AuthRoute user={user} component={Profile} />} />

      <Route path={aur.Dashboard} element={<AuthUser user={user} component={UserDashboard} />} />
      <Route path={aur.Conferences} element={<AuthUser user={user} component={UserConferences} />} />
      <Route path={aur.Conference} element={<AuthUser user={user} component={UserConference} />} />
      <Route path={aur.ConferenceLive} element={<AuthUser user={user} component={UserConferenceLive} />} />
      <Route path={aur.UserPollVote} element={<AuthUser user={user} component={UserPollVote} />} />
      <Route path={aur.UserPollResults} element={<AuthUser user={user} component={UserPollResults} />} />

      <Route path={aur.Support} element={<AuthUser user={user} component={NotImplemented} />} />

      <Route path={aar.Dashboard} element={<AuthAdmin user={user} component={AdminDashboard} />} />
      <Route path={aar.Community} element={<AuthAdmin user={user} component={NotImplemented} />} />
      <Route path={aar.CommunityGroups} element={<AuthAdmin user={user} component={AdminGroups} />} />
      <Route path={aar.NewGroup} element={<AuthAdmin user={user} component={NotImplemented} />} />
      <Route path={aar.CommunityUsers} element={<AuthAdmin user={user} component={AdminUsers} />} />
      <Route path={aar.NewUser} element={<AuthAdmin user={user} component={NotImplemented} />} />
      <Route path={aar.Conferences} element={<AuthAdmin user={user} component={AdminConferences} />} />
      <Route path={aar.Conference} element={<AuthAdmin user={user} component={AdminConference} />} />
      <Route path={aar.ConferenceLive} element={<AuthAdmin user={user} component={AdminConferenceLive} />} />
      <Route path={aar.NewConference} element={<AuthAdmin user={user} component={NewConference} />} />
      <Route path={aar.Messages} element={<AuthAdmin user={user} component={AdminMessages} />} />
      <Route path={aar.Results} element={<AuthAdmin user={user} component={NotImplemented} />} />

      <Route path={aar.Settings} element={<AuthAdmin user={user} component={Settings} />} />
      <Route path={aar.Support} element={<AuthAdmin user={user} component={NotImplemented} />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouterOutlet;
