import { FC } from 'react';
import { Button, Image, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdClose, MdMenu } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthenticatedRoutes } from '../../enums/routes.enum';
import { UserRole } from '../../enums/user-roles.enum';
import { IUser } from '../../interfaces/user.interface';
import { logout } from '../../redux/slices/auth.slice';
import { toggleSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';

interface IProps {
  logo: string;
  user: IUser | null;
}

const Header: FC<IProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();

  const expanded: boolean = useSelector((s: RootState) => s.ui.sidebar.expanded);

  const handleToggle = () => dispatch(toggleSidebar());
  const handleLogout = () => dispatch(logout());

  return (
    <Navbar sticky="top" expand="lg" className="navbar px-3">
      <Navbar.Brand className="p-0">
        <Link to="/">
          <Image src={props.logo} style={{ height: 40 }} />
        </Link>
      </Navbar.Brand>
      <Navbar.Text className="d-block d-lg-none p-0">
        <Button variant="outline-primary" onClick={handleToggle}>
          {expanded ? <MdClose size={18} /> : <MdMenu size={18} />}
        </Button>
      </Navbar.Text>
      <Navbar.Collapse>
        <Nav className="ms-auto">
          {props.user && (
            <Nav.Link as={Link} to={AuthenticatedRoutes.Me}>
              {
                {
                  [UserRole.User]: <span>{props.user.full_name}</span>,
                  [UserRole.Admin]: <span>{props.user.full_name}</span>,
                }[props.user.role]
              }
            </Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
