import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaCrown, FaGenderless, FaUserCog } from 'react-icons/fa';
import { IGroupMember } from '../../interfaces/group.interface';

interface IProps {
  member: IGroupMember;
  isCheckedIn: boolean;
  isPresident: boolean;
  isSecretary: boolean;
  readonly?: boolean;
  onToggleMemberPresence?: (group: IGroupMember) => void;
}

const GroupMember: FC<IProps> = (props) => {
  const { t } = useTranslation();

  const readonly: boolean = props.readonly !== undefined ? props.readonly : true;

  let [checkedIn, setCheckedIn] = useState<boolean>(props.isCheckedIn);

  const toggleMemberPresence = () => {
    setCheckedIn(!checkedIn);
    props.onToggleMemberPresence && props.onToggleMemberPresence(props.member);
  };

  return (
    <Fragment>
      <div className="d-flex">
        <div className="flex-grow-1">
          <p>
            <span>{props.member.nome}</span>
            <span>{props.member.cognome}</span>
          </p>
          {props.member.peso && (
            <b>
              <span>{t('components.group-member.quotas')}</span>
              <span>:</span>
              <span>{props.member.peso?.toFixed(3)}</span>
            </b>
          )}
        </div>
        <div className="flex-shrink-1 text-end">
          <div className="d-flex">
            <div className="flex-grow-1 text-end pe-2">
              {props.isPresident && <FaCrown size={24} className="text-warning" />}
              {props.isSecretary && <FaUserCog size={24} className="text-info" />}
            </div>
            <div className="flex-grow-1 text-end pe-2" onClick={() => toggleMemberPresence()}>
              {checkedIn ? (
                <FaCheckCircle size={24} className="text-success" />
              ) : (
                <FaGenderless size={24} className="text-muted" />
              )}
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-2" />
    </Fragment>
  );
};

export default GroupMember;
