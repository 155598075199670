import { FC, Fragment, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChartBar, FaEdit, FaPlayCircle, FaStopCircle } from 'react-icons/fa';
import { IPoll } from '../../interfaces/poll.interface';
import PollResult from './PollResult';

interface IProps {
  polls: Array<IPoll>;
  readonly?: boolean;
  onEditClick?: (poll: IPoll) => void;
  onStartClick?: (poll: IPoll) => void;
  onStopClick?: (poll: IPoll) => void;
  onReportClick?: (poll: IPoll) => void;
}

const PollList: FC<IProps> = (props) => {
  const { t } = useTranslation();

  const readonly: boolean = props.readonly !== undefined ? props.readonly : true;

  let [modalVisible, setModalVisible] = useState<boolean>(false);
  let [poll, setPoll] = useState<IPoll | null>(null);

  const handleModalClose = () => setModalVisible(false);

  const handleStart = (poll: IPoll) => {
    props.onStartClick && props.onStartClick(poll);
  };

  const handleStop = (poll: IPoll) => {
    props.onStopClick && props.onStopClick(poll);
  };

  const handleEdit = (poll: IPoll) => {
    props.onEditClick && props.onEditClick(poll);
  };

  const handleReport = (poll: IPoll) => {
    props.onReportClick && props.onReportClick(poll);

    setPoll(poll);
    setModalVisible(true);
  };

  return (
    <Fragment>
      {props.polls.map((poll: IPoll) => (
        <Fragment>
          <div data-id={poll.id_votazione.toString()} className="d-flex">
            <div className="flex-grow-1 align-self-center">
              <small className="text-muted">{poll.titolo}</small>
            </div>
            <div className="flex-shrink-1 text-end">
              {!poll.data_fine && (
                <Fragment>
                  {!poll.data_inizio ? (
                    <Fragment>
                      {props.onStartClick && (
                        <Fragment>
                          {readonly ? (
                            <FaPlayCircle className="text-success" />
                          ) : (
                            <Button variant="link" className="text-decoration-none" onClick={() => handleStart(poll)}>
                              <FaPlayCircle className="text-success" />
                            </Button>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {props.onStopClick && (
                        <Fragment>
                          {readonly ? (
                            <FaStopCircle className="text-danger" />
                          ) : (
                            <Button variant="link" className="text-decoration-none" onClick={() => handleStop(poll)}>
                              <FaStopCircle className="text-danger" />
                            </Button>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
            <div className="flex-shrink-1 text-end">
              {!poll.data_inizio ? (
                <Fragment>
                  {props.onEditClick && (
                    <Fragment>
                      {!readonly && (
                        <Button variant="link" className="text-decoration-none" onClick={() => handleEdit(poll)}>
                          <FaEdit className="text-muted" />
                        </Button>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {props.onReportClick && (
                    <Button variant="link" className="text-decoration-none" onClick={() => handleReport(poll)}>
                      <FaChartBar className="text-muted" />
                    </Button>
                  )}
                </Fragment>
              )}
            </div>
          </div>
          <hr className="mt-2" />
        </Fragment>
      ))}
      {poll && (
        <Modal show={modalVisible} onHide={handleModalClose} size="lg" centered fullscreen="md-down">
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="text-info">{t('components.poll-list.modal-title')}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <PollResult poll={poll} />
          </Modal.Body>
        </Modal>
      )}
    </Fragment>
  );
};

export default PollList;
