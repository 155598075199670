import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import { FC } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdEmail, MdGroups, MdHome, MdOutlineBarChart } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { AuthenticatedRoutes, AuthenticatedRoutesAdmin } from '../../enums/routes.enum';
import { IUser } from '../../interfaces/user.interface';

const LINK =
  'https://styles.redditmedia.com/t5_511f6i/styles/profileIcon_29nc5e2hmxm71.jpg?width=256&height=256&crop=256:256,smart&s=7353da910cf8b8c5b0f534c6ab0b545278cb5ed8';

interface IProps {
  user: IUser;
  expanded: boolean;
  selected: string;
  onSelect: (value: string) => void;
}

const SidebarNavAdmin: FC<IProps> = (props) => {
  const { t } = useTranslation();

  const isSelected = (key: string) => props.selected.startsWith(key);

  return (
    <SideNav.Nav defaultSelected={props.selected}>
      {props.expanded && (
        <NavItem
          eventKey={AuthenticatedRoutes.Me}
          className={['profile', isSelected(AuthenticatedRoutes.Me) && 'selected']}
        >
          <div className="text-center my-2">
            <Image src={LINK} height={72} />
          </div>
          <div className="text-center text-muted text-uppercase">{props.user.full_name}</div>
          <div className="text-center">
            <Link
              to={AuthenticatedRoutes.Me}
              onClick={() => props.onSelect(AuthenticatedRoutes.Me)}
              className="text-light text-decoration-none"
            >
              {t('sidebar.user.edit-profile')}
            </Link>
          </div>
        </NavItem>
      )}
      <NavItem
        eventKey={AuthenticatedRoutesAdmin.Dashboard}
        className={isSelected(AuthenticatedRoutesAdmin.Dashboard) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesAdmin.Dashboard}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.Dashboard)}
            className="text-decoration-none"
          >
            <MdHome size={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesAdmin.Dashboard}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.Dashboard)}
            className="text-decoration-none"
          >
            {t('sidebar.admin.home')}
          </Link>
        </NavText>
      </NavItem>
      <NavItem
        eventKey={AuthenticatedRoutesAdmin.CommunityGroups}
        className={isSelected(AuthenticatedRoutesAdmin.CommunityGroups) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesAdmin.CommunityGroups}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.CommunityGroups)}
            className="text-decoration-none"
          >
            <Image src="/assets/community.svg" height={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesAdmin.CommunityGroups}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.CommunityGroups)}
            className="text-decoration-none"
          >
            {t('sidebar.admin.groups')}
          </Link>
        </NavText>
      </NavItem>
      <NavItem
        eventKey={AuthenticatedRoutesAdmin.CommunityUsers}
        className={isSelected(AuthenticatedRoutesAdmin.CommunityUsers) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesAdmin.CommunityUsers}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.CommunityUsers)}
            className="text-decoration-none"
          >
            <MdGroups size={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesAdmin.CommunityUsers}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.CommunityUsers)}
            className="text-decoration-none"
          >
            {t('sidebar.admin.users')}
          </Link>
        </NavText>
      </NavItem>
      <NavItem
        eventKey={AuthenticatedRoutesAdmin.Conferences}
        className={isSelected(AuthenticatedRoutesAdmin.Conferences) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesAdmin.Conferences}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.Conferences)}
            className="text-decoration-none"
          >
            <Image src="/assets/conferences.svg" height={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesAdmin.Conferences}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.Conferences)}
            className="text-decoration-none"
          >
            {t('sidebar.admin.conferences')}
          </Link>
        </NavText>
      </NavItem>
      <NavItem
        eventKey={AuthenticatedRoutesAdmin.Messages}
        className={isSelected(AuthenticatedRoutesAdmin.Messages) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesAdmin.Messages}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.Messages)}
            className="text-decoration-none"
          >
            <MdEmail size={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesAdmin.Messages}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.Messages)}
            className="text-decoration-none"
          >
            {t('sidebar.admin.messages')}
          </Link>
        </NavText>
      </NavItem>
      <NavItem
        eventKey={AuthenticatedRoutesAdmin.Results}
        className={isSelected(AuthenticatedRoutesAdmin.Results) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesAdmin.Results}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.Results)}
            className="text-decoration-none"
          >
            <MdOutlineBarChart size={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesAdmin.Results}
            onClick={() => props.onSelect(AuthenticatedRoutesAdmin.Results)}
            className="text-decoration-none"
          >
            {t('sidebar.admin.results')}
          </Link>
        </NavText>
      </NavItem>
    </SideNav.Nav>
  );
};

export default SidebarNavAdmin;
