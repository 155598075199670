import { IGetConferenceThunkPayload } from '../redux/slices/conference.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getConference = (thunkPayload: IGetConferenceThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.Conference.replace(':conferenceId', thunkPayload.conferenceId));
};

export const ConferenceAPI = {
  getConference,
};

export default ConferenceAPI;
