import { FC, Fragment } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoginBackground from '../components/LoginBackground';
import LoginBackgroundMobile from '../components/LoginBackgroundMobile';
import LoginBackgroundTablet from '../components/LoginBackgroundTablet';
import LoginForm from '../components/LoginForm';

const Login: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <LoginBackgroundMobile />
      <LoginBackgroundTablet />
      <LoginBackground />
      <Container fluid as="section" className="login-content d-flex">
        <Row className="flex-grow-1">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 8 }}>
            <Card>
              <Card.Body>
                <h1 className="m-0 mb-3 text-center text-light">Login</h1>
                <LoginForm verticalSpacing={2} />
                <h6 className="m-0 mt-3 text-center text-light text-underline fw-bold">
                  <a>{t('login.card.linkToResetPassword')}</a>
                </h6>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Login;
