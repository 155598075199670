import { FC, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MessageList from '../../components/admin/MessageList';
import PageTitle from '../../components/PageTitle';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';

const AdminMessages: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <PageTitle content={t('admin-messages.page.title')} />
      <PageSubtitle content={t('admin-messages.page.subtitle')} />
      <PageParagraph content={t('admin-messages.page.paragraph')} />
      <Container fluid as="section">
        <Row>
          <Col>
            <MessageList />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminMessages;
