import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdHelp, MdLogout, MdSettings } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthenticatedRoutesAdmin, AuthenticatedRoutesUser, UnauthenticatedRoutes } from '../../enums/routes.enum';
import { UserRole } from '../../enums/user-roles.enum';
import { IUser } from '../../interfaces/user.interface';
import { logout } from '../../redux/slices/auth.slice';
import { closeSidebar, toggleSidebar } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';
import SidebarNavAdmin from './SidebarNavAdmin';
import SidebarNavUser from './SidebarNavUser';

interface IProps {
  user: IUser;
}

const Sidebar: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const expanded: boolean = useSelector((s: RootState) => s.ui.sidebar.expanded);

  let [selected, setSelected] = useState<string>(
    props.user.role === UserRole.User ? AuthenticatedRoutesUser.Dashboard : AuthenticatedRoutesAdmin.Dashboard
  );

  const isSelected = (key: string) => selected.startsWith(key);

  const handleSelect = (value: string) => {
    setSelected(value);
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    dispatch(closeSidebar());
    dispatch(logout())
      .unwrap()
      .then(() => (window.location.href = UnauthenticatedRoutes.Login));
  };

  return (
    <SideNav
      className={['sidebar', !expanded && 'sidebar-collapsed']}
      expanded={expanded}
      onToggle={() => dispatch(toggleSidebar())}
      onSelect={handleSelect}
    >
      <SideNav.Toggle />
      {
        {
          [UserRole.User]: (
            <SidebarNavUser user={props.user} expanded={expanded} selected={selected} onSelect={handleSelect} />
          ),
          [UserRole.Admin]: (
            <SidebarNavAdmin user={props.user} expanded={expanded} selected={selected} onSelect={handleSelect} />
          ),
        }[props.user.role]
      }
      <SideNav.Nav componentClass="footer">
        {props.user.role === UserRole.Admin && (
          <Fragment>
            <NavItem
              eventKey={AuthenticatedRoutesAdmin.Settings}
              className={isSelected(AuthenticatedRoutesAdmin.Settings) && 'selected'}
            >
              <NavIcon>
                <Link
                  to={AuthenticatedRoutesAdmin.Settings}
                  onClick={() => handleSelect(AuthenticatedRoutesAdmin.Settings)}
                  className="text-decoration-none"
                >
                  <MdSettings size={32} />
                </Link>
              </NavIcon>
              <NavText>
                <Link
                  to={AuthenticatedRoutesAdmin.Settings}
                  onClick={() => handleSelect(AuthenticatedRoutesAdmin.Settings)}
                  className="text-decoration-none"
                >
                  {t('sidebar.common.settings')}
                </Link>
              </NavText>
            </NavItem>
            <NavItem
              eventKey={AuthenticatedRoutesAdmin.Support}
              className={isSelected(AuthenticatedRoutesAdmin.Support) && 'selected'}
            >
              <NavIcon>
                <Link
                  to={AuthenticatedRoutesAdmin.Support}
                  onClick={() => handleSelect(AuthenticatedRoutesAdmin.Support)}
                  className="text-decoration-none"
                >
                  <MdHelp size={32} />
                </Link>
              </NavIcon>
              <NavText>
                <Link
                  to={AuthenticatedRoutesAdmin.Support}
                  onClick={() => handleSelect(AuthenticatedRoutesAdmin.Support)}
                  className="text-decoration-none"
                >
                  {t('sidebar.common.support')}
                </Link>
              </NavText>
            </NavItem>
          </Fragment>
        )}
        <NavItem onClick={handleLogout}>
          <NavIcon>
            <MdLogout size={32} />
          </NavIcon>
          <NavText>{t('sidebar.common.logout')}</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default Sidebar;
