import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IFailResponse, IRejectResponse, IServerResponse, ISuccessResponse } from '../../api/api.interface';
import GroupsAPI from '../../api/groups.api';
import { IGroup } from '../../interfaces/group.interface';
import { parseErrorToRejectResponse, parseRejectServerResponseToRejectResponse } from '../../utils/api-reject-parser';

export interface IGetGroupsFulfilledPayload {
  list: IGroup[],
}

export const getGroups = createAsyncThunk(
  "groups/get",
  async (_thunkPayload: undefined, thunkAPI) => {
    try {
      const response = await GroupsAPI.getGroups();

      const payload = response.data as IServerResponse<IGroup>;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        const successPayload = response.data as ISuccessResponse<IGroup>;

        const list = successPayload.data as IGroup[];

        const fulfillValue = { list } as IGetGroupsFulfilledPayload;

        return thunkAPI.fulfillWithValue(fulfillValue);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IGroupsState {
  error: IRejectResponse | null;
  value: IGroup[];
}

const initialState: IGroupsState = {
  error: null,
  value: [],
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, (state) => {
      state.error = null;
      state.value = [];
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetGroupsFulfilledPayload;

      state.value = payload.list;
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.error = payload;
    });
  },
});

export default groupsSlice.reducer;
