import { add, isBefore } from 'date-fns';
import { IUser } from '../interfaces/user.interface';

const EntityStoreName = 'user';

export interface IStatePayload {
  expire: Date,
  token: string,
  user: IUser;
}

interface IState {
  expire: Date | null,
  token: string | null,
  user: IUser | null;
}

const DefaultState: IState = { expire: null, token: null, user: null };

export class AuthService {
  private static _instance: AuthService = new AuthService();

  public static get instance() {
    return this._instance;
  }

  private state: IState = DefaultState;

  public get expire(): Date | null {
    if (this.isExpired()) {
      this.ClearState();
    }
    return this.state.expire;
  }

  public get token(): string | null {
    if (this.isExpired()) {
      this.ClearState();
    }
    return this.state.token;
  }

  public get user(): IUser | null {
    if (this.isExpired()) {
      this.ClearState();
    }
    return this.state.user;
  }

  constructor() {
    if (AuthService._instance) {
      throw new Error("Error: Instantiation failed: Use AuthService.instance instead of new.");
    }

    AuthService._instance = this;

    this.LoadState();
  }

  public LoadState(): void {
    try {
      const fromLocalStorage = localStorage.getItem(EntityStoreName);
      if (!fromLocalStorage) {
        this.state = DefaultState;
        return;
      }

      const stateFromLocalStorage = JSON.parse(fromLocalStorage) as IState;
      if (!stateFromLocalStorage.expire) {
        this.state = DefaultState;
        return;
      }

      const state = stateFromLocalStorage as IStatePayload;
      state.expire = new Date(state.expire);

      this.state = state;

      if (this.isExpired()) {
        this.state = DefaultState;
      }
    }
    catch (e) {
      console.error(e);
      this.state = DefaultState;
    }
  };

  public StashState(state: IStatePayload) {
    localStorage.setItem(EntityStoreName, JSON.stringify(state));

    this.state = state;
  }

  public ClearState() {
    localStorage.removeItem(EntityStoreName);

    this.state = DefaultState;
  }

  public GenerateExpire() {
    return add(new Date(), { seconds: 300 });
  }

  private isExpired() {
    if (this.state.expire) {
      return isBefore(this.state.expire, Date.now());
    } else {
      return true;
    }
  }
};

export default AuthService;
