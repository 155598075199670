import { FC, Fragment, useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface IProps {
  title?: string;
  titleClass?: string;
  expanded?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
}

const Accordion: FC<IProps> = (props) => {
  let [expanded, setExpanded] = useState<boolean>(props.expanded || false);

  const toggleExpanded = () => {
    setExpanded(!expanded);

    if (expanded) {
      props.onExpand && props.onExpand();
    } else {
      props.onCollapse && props.onCollapse();
    }
  };

  return (
    <Fragment>
      <div className="d-flex" onClick={toggleExpanded}>
        <div className="flex-grow-1 align-self-center">
          {props.title && <small className={`text-uppercase ${props.titleClass || 'text-muted'}`}>{props.title}</small>}
        </div>
        <div className="flex-shrink-1 text-end pe-2">
          {expanded ? <FaCaretUp className="text-muted" /> : <FaCaretDown className="text-muted" />}
        </div>
      </div>
      <hr className="mt-2" />
      {props.children && expanded && <div>{props.children}</div>}
    </Fragment>
  );
};

export default Accordion;
