import { FC, Fragment } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronCircleLeft, FaFile, FaHandPaper, FaUserCheck, FaVideo } from 'react-icons/fa';
import { MdHome } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Jitsi from '../../components/shared/Jitsi';
import { AuthenticatedRoutesUser } from '../../enums/routes.enum';
import { IConference } from '../../interfaces/conference.interface';
import { IUser } from '../../interfaces/user.interface';
import { stopLiveConference } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';

const UserConferenceLive: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { conferenceId } = useParams<{ conferenceId: string }>();

  const conference: IConference | null = useSelector((s: RootState) => s.ui.live?.conference as IConference | null);

  const user: IUser | null = useSelector((s: RootState) => s.auth.user?.value as IUser);

  const handleQuit = () => {
    dispatch(stopLiveConference());
    navigate(AuthenticatedRoutesUser.Conference.replace(':conferenceId', conferenceId || 'not-found'));
  };

  const handleLiveView = () => {};
  const handleGuestsView = () => {};
  const handleVotingView = () => {};
  const handleDocsView = () => {};

  return (
    <Fragment>
      <header className="d-flex align-items-center w-100 position-fixed top-0 header">
        <Container fluid as="section" className="d-flex justify-content-between py-1">
          <div className="flex-shrink-1">
            <FaChevronCircleLeft size={24} onClick={handleQuit} />
          </div>
          <div className="flex-grow-1">
            <h6 className="text-center text-uppercase">conference</h6>
          </div>
          <div className="flex-shrink-1">
            <MdHome size={24} className="invisible" />
          </div>
        </Container>
      </header>
      <section className="content">{user && conference && <Jitsi user={user} conference={conference} />}</section>
      <footer className="d-flex align-items-center w-100 position-fixed bottom-0 footer">
        <Container fluid as="section" className="d-flex justify-content-between py-1">
          <div className="text-center">
            <FaVideo size={24} onClick={handleLiveView} />
            <div>Live</div>
          </div>
          <div className="text-center">
            <FaUserCheck size={24} onClick={handleGuestsView} />
            <div>Guest</div>
          </div>
          <div className="text-center">
            <FaHandPaper size={24} onClick={handleVotingView} />
            <div>Voting</div>
          </div>
          <div className="text-center">
            <FaFile size={24} onClick={handleDocsView} />
            <div>Docs</div>
          </div>
        </Container>
      </footer>
    </Fragment>
  );
};

export default UserConferenceLive;
