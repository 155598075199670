import { IForgotPasswordThunkPayload, ILoginThunkPayload, IRegisterThunkPayload, IResetPasswordThunkPayload, IVerifyThunkPayload } from '../redux/slices/auth.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const register = (payload: IRegisterThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.Register, {
      email: payload.email,
      password: payload.password,
    });
};

export const verify = (payload: IVerifyThunkPayload,) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.VerifyAccount, {
      token: payload.token
    });
};

export const forgot = (payload: IForgotPasswordThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.ForgotPassword, {
      email: payload.email,
    });
};

export const reset = (payload: IResetPasswordThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.ResetPassword, {
      token: payload.token,
      password: payload.password,
    });
};

export const login = (payload: ILoginThunkPayload) => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.Login, {
      username: payload.username,
      password: payload.password,
    });
};


export const logout = () => {
  return AxiosService.instance.axios
    .post(ApiEndpoints.Logout);
};

export const AuthAPI = {
  register,
  verify,
  forgot,
  reset,
  login,
  logout,
};

export default AuthAPI;
