import { Formik } from 'formik';
import { FC, Fragment, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AuthenticatedRoutes } from '../enums/routes.enum';
import { ILoginThunkPayload, login } from '../redux/slices/auth.slice';
import { AppDispatch } from '../redux/store';

interface IFormValue extends ILoginThunkPayload {}

const initialValues: IFormValue = {
  username: '',
  password: '',
};

const adminData: IFormValue = { ...initialValues };
const userData: IFormValue = { ...initialValues };

if (process.env.REACT_APP_ENV === 'development') {
  adminData.username = 'omattia-916';
  adminData.password = 'uscef5t2';
  userData.username = 'pdue-6';
  userData.password = '54T7KeLX';
}

interface IProps {
  verticalSpacing: number;
}
export const LoginForm: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const schema = yup.object().shape({
    username: yup.string().required(t('login.form.errors.fieldRequired')),
    password: yup.string().required(t('login.form.errors.fieldRequired')),
  });

  const handleSubmit = (value: IFormValue) => {
    setLoading(true);

    dispatch(login(value as ILoginThunkPayload))
      .unwrap()
      .then(() => navigate(AuthenticatedRoutes.Me))
      .catch(() => setLoading(false));
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className={`py-${props.verticalSpacing}`}>
              <Form.Group as={Col} controlId="username">
                <Form.Control
                  className="zeesta-input"
                  as="input"
                  type="text"
                  name="username"
                  placeholder={t('login.form.usernamePlaceholder')}
                  disabled={isLoading}
                  value={values.username}
                  onChange={handleChange}
                  isValid={touched.username && !errors.username}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <Form.Group as={Col} controlId="password">
                <Form.Control
                  className="zeesta-input"
                  as="input"
                  type="password"
                  name="password"
                  placeholder={t('login.form.passwordPlaceholder')}
                  disabled={isLoading}
                  value={values.password}
                  onChange={handleChange}
                  isValid={touched.password && !errors.password}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className={`py-${props.verticalSpacing}`}>
              <Col className="text-center">
                <Button type="submit" variant="primary" disabled={isLoading}>
                  {isLoading ? (
                    <Fragment>
                      <Spinner as="span" animation="border" size="sm" role="status" />
                      <span className="ms-1">{t('login.form.submitting')}</span>
                      <span>...</span>
                    </Fragment>
                  ) : (
                    <span>{t('login.form.submit')}</span>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {process.env.REACT_APP_ENV === 'development' && (
        <div className="position ">
          <Button variant="outline-primary" className="w-100 mb-1" onClick={() => handleSubmit(adminData)}>
            Log as Admin
          </Button>
          <Button variant="outline-primary" className="w-100 mt-1" onClick={() => handleSubmit(userData)}>
            Log as User
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default LoginForm;
