import { FC, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IPoll, IPollGroup, IPollOption } from '../../interfaces/poll.interface';

interface IProps {
  poll: IPoll;
}

const PollResult: FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {!!props.poll.data_inizio && !props.poll.data_fine && (
        <div className="py-1">
          <div className="px-3">
            <p className="text-uppercase text-danger">{t('components.poll-result.warning-title')}</p>
            <p className="text-danger">
              <span className="me-1">{t('components.poll-result.warning-subtitle')}</span>
              <b className="me-1">{t('components.poll-result.warning-subtitle-focus')}</b>
            </p>
          </div>
          <hr className="mt-2" />
        </div>
      )}
      <div className="py-1">
        <div className="px-3">
          <p className="text-uppercase text-info">{t('components.poll-result.information')}</p>
          <p>
            <b>{t('components.poll-result.title')}</b>
            <br />
            <span>{props.poll.titolo}</span>
          </p>
          <p>
            <b>{t('components.poll-result.question')}</b>
            <br />
            <span>{props.poll.domanda}</span>
          </p>
          <p>
            <b>{t('components.poll-result.groups')}</b>
            {props.poll.gruppi.map((group: IPollGroup) => (
              <Fragment>
                <br />
                <span>{group.nome_gruppo}</span>
              </Fragment>
            ))}
          </p>
        </div>
        <hr className="mt-2" />
      </div>
      <div className="p-3">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>
                <p className="text-uppercase text-info">{t('components.poll-result.results')}</p>
              </th>
              <th className="text-center">
                <p className="text-uppercase text-muted">{t('components.poll-result.score')}</p>
              </th>
              <th className="text-center">
                <p className="text-uppercase text-muted">{t('components.poll-result.percentage')}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {props.poll.preferenze.map((x: IPollOption) => (
              <tr>
                <td>{x.testo}</td>
                <td className="text-center">{x.score}</td>
                <td className="text-center">{x.percentage}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="p-3">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>
                <p className="text-uppercase text-info">{t('components.poll-result.voters')}</p>
              </th>
              <th className="text-center">
                <p className="text-uppercase text-muted">{props.poll.nrUtentiTotali.toString()}</p>
              </th>
              <th className="text-center">
                <p className="text-uppercase text-muted invisible">NOHEADER</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('components.poll-result.checked-in-members')}</td>
              <td className="text-center">{props.poll.nrUtentiVotanti.toString()}</td>
              <td className="text-center">
                {((props.poll.nrUtentiVotanti * 100) / props.poll.nrUtentiTotali).toFixed(2)}%
              </td>
            </tr>
            <tr>
              <td>{t('components.poll-result.checked-out-members')}</td>
              <td className="text-center">{(props.poll.nrUtentiTotali - props.poll.nrUtentiVotanti).toString()}</td>
              <td className="text-center">
                {(((props.poll.nrUtentiTotali - props.poll.nrUtentiVotanti) * 100) / props.poll.nrUtentiTotali).toFixed(
                  2
                )}
                %
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default PollResult;
