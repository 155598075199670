import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface IProps {
  content: string;
}

const PageParagraph: FC<IProps> = (props) => (
  <Container fluid as="section">
    <Row className="py-2">
      <Col className="justify-content">
        <hr />
        <p>{props.content}</p>
      </Col>
    </Row>
  </Container>
);

export default PageParagraph;
