import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav';
import { FC } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdHelp, MdHome } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { AuthenticatedRoutes, AuthenticatedRoutesUser } from '../../enums/routes.enum';
import { IUser } from '../../interfaces/user.interface';

const LINK = 'https://miro.medium.com/max/621/1*OrZ-Dsh1BfkhNO9G0sD69w.png';

interface IProps {
  user: IUser;
  expanded: boolean;
  selected: string;
  onSelect: (value: string) => void;
}

const SidebarNavUser: FC<IProps> = (props) => {
  const { t } = useTranslation();

  const isSelected = (key: string) => props.selected.startsWith(key);

  return (
    <SideNav.Nav defaultSelected={props.selected}>
      {props.expanded && (
        <NavItem
          eventKey={AuthenticatedRoutes.Me}
          className={['profile', isSelected(AuthenticatedRoutes.Me) && 'selected']}
        >
          <div className="text-center my-2">
            <Image src={LINK} height={72} />
          </div>
          <div className="text-center my-2 text-muted text-uppercase">{props.user.full_name}</div>
          <div className="text-center my-2">
            <Link
              to={AuthenticatedRoutes.Me}
              onClick={() => props.onSelect(AuthenticatedRoutes.Me)}
              className="text-light text-decoration-none"
            >
              {t('sidebar.user.edit-profile')}
            </Link>
          </div>
        </NavItem>
      )}
      <NavItem
        eventKey={AuthenticatedRoutesUser.Dashboard}
        className={isSelected(AuthenticatedRoutesUser.Dashboard) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesUser.Dashboard}
            onClick={() => props.onSelect(AuthenticatedRoutesUser.Dashboard)}
            className="text-decoration-none"
          >
            <MdHome size={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesUser.Dashboard}
            onClick={() => props.onSelect(AuthenticatedRoutesUser.Dashboard)}
            className="text-decoration-none"
          >
            {t('sidebar.user.home')}
          </Link>
        </NavText>
      </NavItem>
      <NavItem
        eventKey={AuthenticatedRoutesUser.Conferences}
        className={isSelected(AuthenticatedRoutesUser.Conferences) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesUser.Conferences}
            onClick={() => props.onSelect(AuthenticatedRoutesUser.Conferences)}
            className="text-decoration-none"
          >
            <Image src="/assets/conferences.svg" height={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesUser.Conferences}
            onClick={() => props.onSelect(AuthenticatedRoutesUser.Conferences)}
            className="text-decoration-none"
          >
            {t('sidebar.user.conferences')}
          </Link>
        </NavText>
      </NavItem>
      <NavItem
        eventKey={AuthenticatedRoutesUser.Support}
        className={isSelected(AuthenticatedRoutesUser.Support) && 'selected'}
      >
        <NavIcon>
          <Link
            to={AuthenticatedRoutesUser.Support}
            onClick={() => props.onSelect(AuthenticatedRoutesUser.Support)}
            className="text-decoration-none"
          >
            <MdHelp size={32} />
          </Link>
        </NavIcon>
        <NavText>
          <Link
            to={AuthenticatedRoutesUser.Support}
            onClick={() => props.onSelect(AuthenticatedRoutesUser.Support)}
            className="text-decoration-none"
          >
            {t('sidebar.common.support')}
          </Link>
        </NavText>
      </NavItem>
    </SideNav.Nav>
  );
};

export default SidebarNavUser;
