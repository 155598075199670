export const DOMAIN = "https://assemblea.opinio.vote/opinio";
//export const DOMAIN = "https://staging.votafacile.it/opinio";

export const enum ApiEndpoints {
  Contact = '/contact',
  Register = '/registration',
  VerifyAccount = '/verify-account',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Login = '/login',
  Logout = '/logout',
  GroupList = '/community/groups',
  UserList = '/community/users',
  User = '/community/users/:id_user',
  ConferenceList = '/conferences',
  Conference = '/conferences/:conferenceId',
}
