import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IFailResponse, IRejectResponse, IServerResponse, ISuccessResponse } from '../../api/api.interface';
import ConferencesAPI from '../../api/conferences.api';
import { IConference } from '../../interfaces/conference.interface';
import { parseErrorToRejectResponse, parseRejectServerResponseToRejectResponse } from '../../utils/api-reject-parser';

export interface IGetConferencesFulfilledPayload {
  list: IConference[],
}

export const getConferences = createAsyncThunk(
  "conferences/get",
  async (_thunkPayload: undefined, thunkAPI) => {
    try {
      const response = await ConferencesAPI.getConferences();

      const payload = response.data as IServerResponse<IConference>;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        const successPayload = response.data as ISuccessResponse<IConference[]>;

        const list = successPayload.data as IConference[];

        const fulfillValue = { list } as IGetConferencesFulfilledPayload;

        return thunkAPI.fulfillWithValue(fulfillValue);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IConferencesState {
  error: IRejectResponse | null;
  value: IConference[];
}

const initialState: IConferencesState = {
  error: null,
  value: [],
};

const conferencesSlice = createSlice({
  name: "conferences",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConferences.pending, (state) => {
      state.error = null;
      state.value = [];
    });
    builder.addCase(getConferences.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetConferencesFulfilledPayload;

      state.value = payload.list;
    });
    builder.addCase(getConferences.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.error = payload;
    });
  },
});

export default conferencesSlice.reducer;
