import { FC, Fragment } from 'react';
import { Col, Container, Row, Card, Button, Table } from 'react-bootstrap';
import { MdCancel } from 'react-icons/md';


const UserPollResults: FC = () => {

    return (
        <Fragment>
            <Card border="light" style={{ width: '24rem' }}>
                <Card.Header className="mb-4" as="h1">Poll results <Button className="bg-outline-secondary"><MdCancel size={24} /></Button></Card.Header>
                <Card.Body>
                    <Card.Title className="mb-4" as="h3">INFORMATION</Card.Title>
                    <Card.Subtitle as="h5">Title</Card.Subtitle>
                    <Card.Text className="mb-4">New door (on Floor 2)</Card.Text>

                    <Card.Subtitle as="h5">Description</Card.Subtitle>
                    <Card.Text className="mb-4">Do you want it?</Card.Text>

                    <Table responsive="sm" className="mb-4">
                        <thead>
                            <tr>
                                <th>Result</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Vote</th>
                                <th>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Yellow</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>3</td>
                                <td>60%</td>
                            </tr>
                            <tr>
                                <td>White</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>6</td>
                                <td>80%</td>
                            </tr>
                            <tr>
                                <td>Blue</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                                <td>30%</td>
                            </tr>
                            <tr>
                                <td>Purple</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>0</td>
                                <td>0%</td>
                            </tr>
                            <tr>
                                <td>Green</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>7</td>
                                <td>90%</td>
                            </tr>

                        </tbody>
                    </Table>
                    <Table responsive="sm" className="mb-4">
                        <thead>
                            <tr>
                                <th>VOTERS</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Vote</th>
                                <th>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Totals</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>6</td>
                                <td>100%</td>
                            </tr>
                            <tr>
                                <td>Sebastianovich</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>0</td>
                                <td>0%</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Row>
                        <Col></Col>
                        <Col><Button className="primary">Download results</Button></Col>
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default UserPollResults;




