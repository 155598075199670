import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '../enums/user-roles.enum';
import { IConference } from '../interfaces/conference.interface';
import { IUser } from '../interfaces/user.interface';
import { RootState } from '../redux/store';
import Footer from './parts/Footer';
import Header from './parts/Header';
import Sidebar from './parts/Sidebar';

interface IProp {
  logo: string;
  user: IUser;
}

const LayoutAdmin: FC<IProp> = (props) => (
  <Fragment>
    <Header logo={props.logo} user={props.user} />
    <Sidebar user={props.user} />
    <main>{props.children}</main>
  </Fragment>
);

export default LayoutAdmin;
