import { FC, Fragment } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PageParagraph from '../../components/PageParagraph';
import PageTitle from '../../components/PageTitle';
import ConferenceList from '../../components/user/ConferenceList';

const UserConferences: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <PageTitle content={t('user-conferences.page.title')} />
      <PageParagraph content={t('user-conferences.page.paragraph')} />
      <Container fluid as="section">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <ConferenceList />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserConferences;
