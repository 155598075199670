import { FC, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GroupList from '../../components/admin/GroupList';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';
import { AuthenticatedRoutesAdmin } from '../../enums/routes.enum';

const AdminGroups: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <PageTitle content={t('admin-groups.page.title')} />
      <PageSubtitle content={t('admin-groups.page.subtitle')}>
        <Link to={AuthenticatedRoutesAdmin.NewGroup}>
          <button className="btn-sm btn-primary mt-auto">{t('admin-groups.actions.addGroup')}</button>
        </Link>
      </PageSubtitle>
      <PageParagraph content={t('admin-groups.page.paragraph')} />
      <Container fluid as="section">
        <Row>
          <Col>
            <GroupList />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminGroups;
