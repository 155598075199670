import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IFailResponse, IRejectResponse, IServerResponse, ISuccessResponse } from '../../api/api.interface';
import ConferenceAPI from '../../api/conference.api';
import { IConference } from '../../interfaces/conference.interface';
import { parseErrorToRejectResponse, parseRejectServerResponseToRejectResponse } from '../../utils/api-reject-parser';

export interface IGetConferenceThunkPayload {
  conferenceId: string,
}

export interface IGetConferenceFulfilledPayload {
  conference: IConference,
}

export const getConference = createAsyncThunk(
  "conference/get",
  async (thunkPayload: IGetConferenceThunkPayload, thunkAPI) => {
    try {
      const response = await ConferenceAPI.getConference(thunkPayload);

      const payload = response.data as IServerResponse<IConference>;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        const successPayload = response.data as ISuccessResponse<IConference>;

        const conference = successPayload.data as IConference;

        const fulfillValue = { conference } as IGetConferenceFulfilledPayload;

        return thunkAPI.fulfillWithValue(fulfillValue);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IConferenceState {
  error: IRejectResponse | null;
  value: IConference | null;
}

const initialState: IConferenceState = {
  error: null,
  value: null,
};

const conferenceSlice = createSlice({
  name: "conference",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConference.pending, (state) => {
      state.value = null;
      state.error = null;
    });
    builder.addCase(getConference.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetConferenceFulfilledPayload;

      state.value = payload.conference;
    });
    builder.addCase(getConference.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.error = payload;
    });
  },
});

export default conferenceSlice.reducer;
