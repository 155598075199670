import { FC, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthenticatedRoutesAdmin, AuthenticatedRoutesUser } from '../enums/routes.enum';
import { UserRole } from '../enums/user-roles.enum';
import { IUser } from '../interfaces/user.interface';

interface IProps {
  user: IUser | null;
  component: FC;
}

export const UnauthenticatedRoute: FC<IProps> = ({ user, component: Component }) => {
  return !user ? (
    <Component />
  ) : (
    <Fragment>
      {
        {
          [UserRole.User]: <Navigate to={AuthenticatedRoutesUser.Dashboard} />,
          [UserRole.Admin]: <Navigate to={AuthenticatedRoutesAdmin.Dashboard} />,
        }[user.role]
      }
    </Fragment>
  );
};

export default UnauthenticatedRoute;
