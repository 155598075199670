import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { IFailResponse, IRejectResponse, IServerResponse, ISuccessResponse } from '../../api/api.interface';
import AuthAPI from '../../api/auth.api';
import { IUser } from '../../interfaces/user.interface';
import AuthService from '../../services/auth.service';
import AxiosService from '../../services/axios.service';
import { parseErrorToRejectResponse, parseRejectServerResponseToRejectResponse } from '../../utils/api-reject-parser';

export interface IRegisterThunkPayload {
  email: string,
  password: string;
}
export interface IRegisterFulfilledPayload { }

export const register = createAsyncThunk(
  'auth/register',
  async (thunkPayload: IRegisterThunkPayload, thunkAPI) => {
    try {
      await AuthAPI.register(thunkPayload);
      return thunkAPI.fulfillWithValue({} as IRegisterFulfilledPayload);

      // const response = await AuthAPI.register(thunkPayload);

      // const payload = response.data as IServerResponse;
      // if (!payload.success) {
      //   const failPayload = response.data as IFailResponse;
      //   const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

      //   return thunkAPI.rejectWithValue(rejectValue);
      // } else {
      //   return thunkAPI.fulfillWithValue({} as IRegisterFulfilledPayload);
      // }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IVerifyThunkPayload {
  token: string,
}
export interface IVerifyFulfilledPayload { }

export const verify = createAsyncThunk(
  'auth/verify',
  async (thunkPayload: IVerifyThunkPayload, thunkAPI) => {
    try {
      await AuthAPI.verify(thunkPayload);
      return thunkAPI.fulfillWithValue({} as IVerifyFulfilledPayload);

      // const response = await AuthAPI.verify(thunkPayload);

      // const payload = response.data as IServerResponse;
      // if (!payload.success) {
      //   const failPayload = response.data as IFailResponse;
      //   const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

      //   return thunkAPI.rejectWithValue(rejectValue);
      // } else {
      //   return thunkAPI.fulfillWithValue({} as IVerifyFulfilledPayload);
      // }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IForgotPasswordThunkPayload {
  email: string,
}
export interface IForgotFulfilledPayload { }

export const forgot = createAsyncThunk(
  "auth/forgot-password",
  async (thunkPayload: IForgotPasswordThunkPayload, thunkAPI) => {
    try {
      await AuthAPI.forgot(thunkPayload);
      return thunkAPI.fulfillWithValue({} as IForgotFulfilledPayload);

      // const response = await AuthAPI.forgot(thunkPayload);

      // const payload = response.data as IServerResponse;
      // if (!payload.success) {
      //   const failPayload = response.data as IFailResponse;
      //   const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

      //   return thunkAPI.rejectWithValue(rejectValue);
      // } else {
      //   return thunkAPI.fulfillWithValue({} as IForgotFulfilledPayload);
      // }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IResetPasswordThunkPayload {
  token: string,
  password: string,
}
export interface IResetFulfilledPayload { }

export const reset = createAsyncThunk(
  "auth/reset-password",
  async (thunkPayload: IResetPasswordThunkPayload, thunkAPI) => {
    try {
      await AuthAPI.reset(thunkPayload);
      return thunkAPI.fulfillWithValue({} as IResetFulfilledPayload);

      // const response = await AuthAPI.reset(thunkPayload);

      // const payload = response.data as IServerResponse;
      // if (!payload.success) {
      //   const failPayload = response.data as IFailResponse;
      //   const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

      //   return thunkAPI.rejectWithValue(rejectValue);
      // } else {
      //   return thunkAPI.fulfillWithValue({} as IResetFulfilledPayload);
      // }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ILoginThunkPayload {
  username: string,
  password: string;
}
export interface ILoginFulfilledPayload {
  token: string;
  user: IUser;
}

export const login = createAsyncThunk(
  "auth/login",
  async (thunkPayload: ILoginThunkPayload, thunkAPI) => {
    try {
      const response = await AuthAPI.login(thunkPayload);

      const payload = response.data as IServerResponse<IUser>;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        const successPayload = response.data as ISuccessResponse<IUser>;

        const user = successPayload.data as IUser;

        const fulfillValue: ILoginFulfilledPayload = {
          token: response.headers['x-auth-token'],
          user,
        } as ILoginFulfilledPayload;

        return thunkAPI.fulfillWithValue(fulfillValue);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface ILogoutFulfilledPayload { }

export const logout = createAsyncThunk(
  "auth/logout",
  async (_thunkPayload: undefined, thunkAPI) => {
    try {
      await AuthAPI.logout();
      return thunkAPI.fulfillWithValue({} as ILoginFulfilledPayload);

      // const response = await AuthAPI.logout(thunkPayload);

      // const payload = response.data as IServerResponse;
      // if (!payload.success) {
      //   const failPayload = response.data as IFailResponse;
      //   const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

      //   return thunkAPI.rejectWithValue(rejectValue);
      // } else {
      //   return thunkAPI.fulfillWithValue({} as ILoginFulfilledPayload);
      // }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IUserState {
  error: IRejectResponse | null;
  expire: string | null;
  token: string | null;
  value: IUser | null,
}

export interface IRegisterState {
  error: IRejectResponse | null;
  timestamp: string | null;
}

export interface IVerifyState {
  error: IRejectResponse | null;
  timestamp: string | null;
}

export interface IForgotState {
  error: IRejectResponse | null;
  timestamp: string | null;
}

export interface IResetState {
  error: IRejectResponse | null;
  timestamp: string | null;
}

export interface IAuthState {
  user: IUserState | null;
  register: IRegisterState | null;
  verify: IVerifyState | null;
  forgot: IForgotState | null;
  reset: IResetState | null;
}

const initialState: IAuthState = {
  user: {
    error: null,
    expire: AuthService.instance.expire?.toDateString() || null,
    token: AuthService.instance.token,
    value: AuthService.instance.user,
  },
  register: null,
  verify: null,
  forgot: null,
  reset: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.register = null;
    });
    builder.addCase(register.fulfilled, (state) => {
      state.register = {
        error: null,
        timestamp: new Date().toISOString(),
      };
    });
    builder.addCase(register.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.register = {
        error: payload,
        timestamp: null,
      };
    });
    builder.addCase(verify.pending, (state) => {
      state.verify = null;
    });
    builder.addCase(verify.fulfilled, (state) => {
      state.register = {
        error: null,
        timestamp: new Date().toISOString(),
      };
    });
    builder.addCase(verify.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.verify = {
        error: payload,
        timestamp: null,
      };
    });
    builder.addCase(forgot.pending, (state) => {
      state.forgot = null;
    });
    builder.addCase(forgot.fulfilled, (state) => {
      state.forgot = {
        error: null,
        timestamp: new Date().toISOString(),
      };
    });
    builder.addCase(forgot.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.forgot = {
        error: payload,
        timestamp: null,
      };
    });
    builder.addCase(reset.pending, (state) => {
      state.reset = null;
    });
    builder.addCase(reset.fulfilled, (state, action) => {
      state.reset = {
        error: null,
        timestamp: new Date().toISOString(),
      };
    });
    builder.addCase(reset.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.reset = {
        error: payload,
        timestamp: null,
      };
    });
    builder.addCase(login.pending, (state) => {
      state.user = null;

      AuthService.instance.ClearState();
      AxiosService.instance.ClearState();
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const payload = action.payload as unknown as ILoginFulfilledPayload;

      const expire = AuthService.instance.GenerateExpire();

      state.user = {
        error: null,
        expire: expire.toISOString(),
        token: payload.token,
        value: payload.user,
      };

      AuthService.instance.StashState({
        expire,
        token: payload.token,
        user: payload.user
      });
      AxiosService.instance.LoadState();
    });
    builder.addCase(login.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.user = {
        error: payload,
        expire: null,
        token: null,
        value: null,
      };
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;

      AuthService.instance.ClearState();
      AxiosService.instance.ClearState();
    });
  },
});

export default authSlice.reducer;
