import { FC, Fragment, useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaDownload, FaPlayCircle, FaStopCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { mock } from '../../api/api.mock';
import PageTitle from '../../components/PageTitle';
import Accordion from '../../components/shared/Accordion';
import DocumentList from '../../components/shared/DocumentList';
import GroupList from '../../components/shared/GroupList';
import Jitsi from '../../components/shared/Jitsi';
import PollList from '../../components/shared/PollList';
import { Routes } from '../../enums/routes.enum';
import { IConference } from '../../interfaces/conference.interface';
import { IDocument } from '../../interfaces/document.interface';
import { IGroup, IGroupMember } from '../../interfaces/group.interface';
import { IPoll } from '../../interfaces/poll.interface';
import { IUser } from '../../interfaces/user.interface';
import { getConference, IGetConferenceFulfilledPayload } from '../../redux/slices/conference.slice';
import { startLiveConference } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';
import AuthService from '../../services/auth.service';

const AdminConferenceLive: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { conferenceId } = useParams<{ conferenceId: string }>();

  const user: IUser | null = AuthService.instance.user;

  const conference: IConference | null = useSelector((s: RootState) => s.conference.value);

  let [documents, setDocuments] = useState<IDocument[]>(
    Array.from(mock.documents as unknown as Array<IDocument>).sort((a, b) => a.name.localeCompare(b.name))
  );

  let [polls, setPolls] = useState<IPoll[]>(
    Array.from(mock.polls as unknown as Array<IPoll>).sort((a, b) => a.titolo.localeCompare(b.titolo))
  );

  let [groups, setGroups] = useState<IGroup[]>(
    Array.from(mock.groups as unknown as Array<IGroup>).sort((a, b) => a.nome.localeCompare(b.nome))
  );

  useEffect(() => {
    if (!conferenceId) return;

    dispatch(getConference({ conferenceId }))
      .unwrap()
      .then((args) => {
        const payload = args as unknown as IGetConferenceFulfilledPayload;
        dispatch(startLiveConference(payload.conference));
      })
      .catch(() => navigate(Routes.NotFound));
  }, [dispatch]);

  const conferenceLive: IConference | null = useSelector((s: RootState) => s.ui.live?.conference as IConference | null);

  const handleDocumentDelete = (document: IDocument) => {
    console.debug('handleDocumentDelete', document);
    const index = documents.findIndex((x: IDocument) => x.id === document.id);
    documents.splice(index, 1);
    setDocuments(Array.from(documents.sort((a, b) => a.name.localeCompare(b.name))));
  };

  const handlePollEdit = (poll: IPoll) => {
    console.debug('handlePollEdit', poll);
  };

  const handlePollStart = (poll: IPoll) => {
    console.debug('handlePollStart', poll);

    poll.data_inizio = new Date().toISOString();

    const index = polls.findIndex((x: IPoll) => x.id_votazione === poll.id_votazione);
    polls.splice(index, 1);
    polls.push(poll);
    setPolls(Array.from(polls.sort((a, b) => a.titolo.localeCompare(b.titolo))));
  };

  const handlePollStop = (poll: IPoll) => {
    console.debug('handlePollStop', poll);

    poll.data_fine = new Date().toISOString();

    const index = polls.findIndex((x: IPoll) => x.id_votazione === poll.id_votazione);
    polls.splice(index, 1);
    polls.push(poll);
    setPolls(Array.from(polls.sort((a, b) => a.titolo.localeCompare(b.titolo))));
  };

  const handlePollReport = (poll: IPoll) => {
    console.debug('handlePollReport', poll);
  };

  const handleTogglePresence = (member: IGroupMember) => {
    console.debug('handleTogglePresence', member);
  };

  return (
    user &&
    conference && (
      <Fragment>
        <PageTitle
          content={t('admin-conference-live.page.title')}
          childrenClasses="d-flex align-items-end flex-row col"
        >
          <h3 className="m-0 w-100 text-end">
            <Badge>
              <div className="d-inline-block text-uppercase" style={{ transform: 'translateY(3px)' }}>
                {t('admin-conference-live.actions.status')}
              </div>
              <FaPlayCircle size={24} className="ms-2 text-success" />
              <FaCheckCircle size={24} className="ms-2" />
              <FaStopCircle size={24} className="ms-2" />
              <FaDownload size={24} className="ms-2" />
            </Badge>
          </h3>
        </PageTitle>
        <Container fluid as="section">
          <Row>
            <Col xs={12} md={4}>
              <Card className="my-3">
                <Card.Body className="p-0" style={{ height: '500px' }}>
                  {conferenceLive && <Jitsi user={user} conference={conference} />}
                </Card.Body>
              </Card>
              <Card className="my-3">
                <Card.Title className="p-3 d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <h5 className="text-uppercase text-primary">{t('admin-conference-live.cards.documents.title')}</h5>
                  </div>
                  <div className="flex-shrink-1">
                    <Button size="sm" disabled>
                      {t('admin-conference-live.cards.documents.action')}
                    </Button>
                  </div>
                </Card.Title>
                <Card.Body>
                  <DocumentList documents={documents} readonly={false} onDeleteClick={handleDocumentDelete} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="my-3">
                <Card.Title className="p-3 d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <h5 className="text-uppercase text-primary">{t('admin-conference-live.cards.polls.title')}</h5>
                  </div>
                  <div className="flex-shrink-1">
                    <Button size="sm" disabled>
                      {t('admin-conference-live.cards.polls.action')}
                    </Button>
                  </div>
                </Card.Title>
                <Card.Body>
                  <Accordion
                    title={t('admin-conference-live.cards.polls.scheduled')}
                    titleClass="text-info"
                    expanded={true}
                  >
                    <PollList
                      polls={polls.filter((poll: IPoll) => !poll.data_inizio && !poll.data_fine)}
                      readonly={false}
                      onEditClick={handlePollEdit}
                      onStartClick={handlePollStart}
                      onStopClick={handlePollStop}
                      onReportClick={handlePollReport}
                    />
                  </Accordion>
                  <Accordion title={t('admin-conference-live.cards.polls.open')} titleClass="text-info" expanded={true}>
                    <PollList
                      polls={polls.filter((poll: IPoll) => !!poll.data_inizio && !poll.data_fine)}
                      readonly={false}
                      onEditClick={handlePollEdit}
                      onStartClick={handlePollStart}
                      onStopClick={handlePollStop}
                      onReportClick={handlePollReport}
                    />
                  </Accordion>
                  <Accordion
                    title={t('admin-conference-live.cards.polls.closed')}
                    titleClass="text-info"
                    expanded={true}
                  >
                    <PollList
                      polls={polls.filter((poll: IPoll) => !!poll.data_inizio && !!poll.data_fine)}
                      readonly={false}
                      onEditClick={handlePollEdit}
                      onStartClick={handlePollStart}
                      onStopClick={handlePollStop}
                      onReportClick={handlePollReport}
                    />
                  </Accordion>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="my-3">
                <Card.Title className="p-3 d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <h5 className="text-uppercase text-primary">{t('admin-conference-live.cards.groups.title')}</h5>
                  </div>
                  <div className="flex-shrink-1">
                    <Button size="sm" disabled>
                      {t('admin-conference-live.cards.groups.action')}
                    </Button>
                  </div>
                </Card.Title>
                <Card.Body>
                  <GroupList groups={groups} readonly={false} onToggleMemberPresence={handleTogglePresence} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  );
};

export default AdminConferenceLive;
