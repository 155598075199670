import { Formik } from 'formik';
import { t } from 'i18next';
import { FC, Fragment, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import * as yup from 'yup';
import { IGetUserThunkPayload, getUser } from '../redux/slices/user.slice';
import { IProfile } from '../interfaces/attendee.interface';

interface IGetUserPayload extends IGetUserThunkPayload { }

let payloadInitValues: IGetUserPayload = {
    id: 0
};

interface IFormValue extends IProfile { }

let initialValues: IFormValue = {
  id: 0,
  username: '',
  nome: '',
  cognome: '',
  email: '',
  telefono: '',
  expires: 0,
  peso: 0,
  roles: [],
  credentialsNonExpired: true,
  enabled: true,
  cognomeNome: '',
  consenso: (-1),
  idAzienda: 0,
  accountNonExpired: true,
  nomeCognome: '',
  accountNonLocked: true,
};

export const ProfileForm: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  
  initialValues = useSelector((s: RootState) => s.profile.value as IProfile);

  const userPayload = useSelector((s: RootState) => {
    if(s.auth.user?.value?.id) {
        payloadInitValues.id = s.auth.user.value.id;
    }
    return payloadInitValues as IGetUserPayload;
  });

  const [isLoading, setLoading] = useState(true);

  const schema = yup.object().shape({
    nome: yup
      .string()
      .required(t('profile.form.errors.fieldRequired'))
      .min(3, t('profile.form.errors.fieldTooShort')),
    cognome: yup
      .string()
      .required(t('profile.form.errors.fieldRequired'))
      .min(3, t('profile.form.errors.fieldTooShort')),
    email: yup
      .string()
      .required(t('profile.form.errors.fieldRequired'))
      .email(t('profile.form.errors.invalidValueEmail')),
    telefono: yup
      .string()
      .required(t('profile.form.errors.fieldRequired'))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        t('profile.form.errors.invalidValuePhone')
      ),
  });

  const handleSubmit = (value: IFormValue) => {
    //setLoading(true);

    //dispatch(contact(value as IContactThunkPayload))
    //  .unwrap()
    //  .then(() => setSubmitted(true))
    //  .catch(() => setSubmitted(false))
    //  .finally(() => setLoading(false));
  };

  const [submitted, setSubmitted] = useState(true);
  
  useEffect(() => {
    dispatch(getUser(userPayload as unknown as IGetUserThunkPayload))
    .then((res) => {
      setSubmitted(false);
      /*
      console.log(res);
      let payload_o: IGetUserFulfilledPayload | null = null;
      if(res?.payload) {
        console.log(payload_o);
        payload_o = res.payload as unknown as IGetUserFulfilledPayload;
      }
      console.log(payload_o);
      if(payload_o) {
        initialValues = payload_o.item as unknown as IFormValue;
      }
      console.log(initialValues);
      */
    });
  }, [dispatch]);

  return (
    <Fragment>
      {submitted ? (
        <div className="text-center">
          <CheckCircleFill size={94} className="text-primary mb-5" />
          <h3 className="text-primary">{t('profile.form.successTitle')}</h3>
          <p className="mt-3">{t('profile.form.successDescription')}</p>
        </div>
      ) : (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} xs="12" md="6" controlId="firstNameLabelValidationFormik">
                  <Form.Label>{t('profile.form.firstNameLabel')}</Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    size="lg"
                    name="nome"
                    placeholder={t('profile.form.firstNamePlaceholder')}
                    disabled={isLoading}
                    value={values?.nome}
                    onChange={handleChange}
                    isInvalid={!!values?.nome && !!errors.nome}
                  />
                  {!!values?.nome && !!errors.nome && (
                    <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs="12" md="6" controlId="lastNameLabelValidationFormik">
                  <Form.Label>{t('profile.form.lastNameLabel')}</Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    size="lg"
                    name="cognome"
                    placeholder={t('profile.form.lastNamePlaceholder')}
                    disabled={isLoading}
                    value={values?.cognome}
                    onChange={handleChange}
                    isInvalid={!!values?.cognome && !!errors.cognome}
                  />
                  {!!values?.cognome && !!errors.cognome && (
                    <Form.Control.Feedback type="invalid">{errors.cognome}</Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="emailValidationFormik">
                  <Form.Label>{t('profile.form.emailLabel')}</Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    size="lg"
                    name="email"
                    placeholder={t('profile.form.emailPlaceholder')}
                    disabled={isLoading}
                    value={values?.email}
                    onChange={handleChange}
                    isInvalid={!!values?.email && !!errors.email}
                  />
                  {!!values?.email && !!values.email && (
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="phoneValidationFormik">
                  <Form.Label>{t('profile.form.phoneLabel')}</Form.Label>
                  <Form.Control
                    as="input"
                    type="text"
                    size="lg"
                    name="telefono"
                    placeholder={t('profile.form.phonePlaceholder')}
                    disabled={isLoading}
                    value={values?.telefono}
                    onChange={handleChange}
                    isInvalid={!!values?.telefono && !!errors.telefono}
                  />
                  {!!values?.telefono && !!errors.telefono && (
                    <Form.Control.Feedback type="invalid">{errors.telefono}</Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              { /*}
              <Row className="mb-3">
                <Col>
                  <Button type="submit" className="w-100" size="lg" variant="primary" disabled={isLoading}>
                    {isLoading ? (
                      <Fragment>
                        <Spinner as="span" animation="border" size="sm" role="status" />
                        <span className="ms-1">{t('profile.form.submitting')}</span>
                        <span>...</span>
                      </Fragment>
                    ) : (
                      <span>{t('profile.form.submit')}</span>
                    )}
                  </Button>
                </Col>
              </Row>
              {*/}
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  );
};

export default ProfileForm;
