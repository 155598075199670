import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedRoutesUser, Routes } from '../../enums/routes.enum';
import { IConference } from '../../interfaces/conference.interface';
import { getConference } from '../../redux/slices/conference.slice';
import { startLiveConference } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';

interface IProps {
  conferenceId: string;
}

const Conference: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  let [stash, setStash] = useState<IConference | undefined>();
  let [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout>();

  const conference: IConference | null = useSelector((s: RootState) => s.conference.value);

  useEffect(() => refresh(), [dispatch]);

  useEffect(() => {
    if (!conference) return;

    setStash(conference);
    console.debug('stash', stash);
  }, [dispatch, conference]);

  const refresh = () => {
    dispatch(getConference({ conferenceId: props.conferenceId }))
      .unwrap()
      .then(() => setTimeoutRef(setTimeout(() => refresh(), 5000)))
      .catch(() => navigate(Routes.NotFound));
  };

  const handleNavigate = () => {
    if (!stash) return;
    if (timeoutRef) clearTimeout(timeoutRef);

    dispatch(startLiveConference(stash));
    navigate(AuthenticatedRoutesUser.ConferenceLive.replace(':conferenceId', stash.id_conference.toString()));
  };

  return (
    <Card>
      <Card.Body>
        <div className="p-3">
          <h3 className="text-info">{t('user-conference.page.conference-info')}</h3>
          <div className="mt-3">
            <b>{t('user-conference.page.title')}</b>
            <div>{stash?.title}</div>
            <hr className="mt-0" />
          </div>
          <div className="mt-3">
            <b>{t('user-conference.page.description')}</b>
            <div>{stash?.description}</div>
            <hr className="mt-0" />
          </div>
          <div className="mt-3">
            <b>{t('user-conference.page.date')}</b>
            <div>{stash?.start_date && format(new Date(stash.start_date), 'dd.MM.yyyy')}</div>
            <hr className="mt-0" />
          </div>
          <div className="mt-3">
            <b>{t('user-conference.page.time')}</b>
            <div>{stash?.start_date && format(new Date(stash.start_date), 'HH:mm')}</div>
            <hr className="mt-0" />
          </div>
        </div>
        <div className="p-3 my-3">
          <h3>{t('user-conference.page.conference-attachments')}</h3>
          <div className="mt-3">
            <em>None</em>
            <hr className="mt-0" />
          </div>
        </div>
        <div className="p-3 text-center">
          {stash?.begin_date ? (
            <button className="btn btn-success w-100" onClick={handleNavigate}>
              {t('user-conference.actions.join')}
            </button>
          ) : (
            <button className="btn btn-disabled w-100" disabled>
              {t('user-conference.actions.wait')}
            </button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Conference;
