import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { IConference } from '../interfaces/conference.interface';
import { IUser } from '../interfaces/user.interface';
import { RootState } from '../redux/store';
import Header from './parts/Header';
import Sidebar from './parts/Sidebar';

interface IProp {
  logo: string;
  user: IUser;
}

const LayoutUser: FC<IProp> = (props) => {
  const live: IConference | null = useSelector((s: RootState) => (s.ui.live?.conference as IConference) || null);

  return (
    <Fragment>
      {live ? (
        <main className="live">{props.children}</main>
      ) : (
        <Fragment>
          <Header logo={props.logo} user={props.user} />
          <Sidebar user={props.user} />
          <main>{props.children}</main>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LayoutUser;
