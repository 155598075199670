import { FC } from 'react';

const SuspenseFallback: FC = () => (
  <main className="suspense">
    <section className="login-background">
      <div className="logo"></div>
      <div className="moon"></div>
      <div className="pilot"></div>
    </section>
  </main>
);

export default SuspenseFallback;
