import { FC, Fragment } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineLine } from 'react-icons/ai';
import { BsCircle, BsCircleFill, BsFillTrashFill } from 'react-icons/bs';
import { IoMdInformationCircle } from 'react-icons/io';
import PageTitle from '../../components/PageTitle';

const NewConference: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <PageTitle content={t('admin-new-conference.page.title')} />
      <Container fluid as="section">
        <Row>
          <Card className="mb-5">
            <Card.Header>
              <Row>
                <Col>
                  <h5 className="m-0 mt-auto page-title text-uppercase ">
                    {t('step 1 - informazioni principali')} &nbsp;
                    <IoMdInformationCircle />
                  </h5>
                </Col>
                <Col>
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Card.Subtitle className="mb-1">Titolo</Card.Subtitle>
                  <Form.Control className="mb-3" type="text" placeholder="conference title" />
                </Form.Group>

                <Form.Group className="mb-3" as={Col} controlId="formGridEmail">
                  <Card.Subtitle className="mb-1">Data</Card.Subtitle>
                  <Form.Group controlId="duedate">
                    <Form.Control type="date" placeholder="date" />
                  </Form.Group>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Card.Subtitle className="mb-1">Descrizione</Card.Subtitle>
                  <Form.Control as="textarea" aria-label="With textarea" placeholder="" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Card.Subtitle className="mb-1">Ora inizio</Card.Subtitle>
                  <Form.Control type="time" placeholder="17:00" />
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <Card className="mb-5">
            <Card.Header>
              <Row>
                <Col>
                  <h5 className="m-0 mt-auto page-title text-uppercase ">
                    {t('step 2 - Gruppi')} &nbsp;
                    <IoMdInformationCircle />
                  </h5>
                </Col>
                <Col>
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Card.Subtitle className="mb-1">Aggiungi gruppi</Card.Subtitle>
                  <Form.Label>Seleziona i gruppi dell'assemblea</Form.Label>
                  <Form.Control
                    type="search"
                    className="autocomplete"
                    placeholder="Inserisci almeno 3 caratteri"
                  />
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Condominio Villa dei Fiori" />
                    <Form.Check type="checkbox" label="Condominio Azzurra" />
                  </Form.Group>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Card.Subtitle className="mb-4">Gruppi selezionati</Card.Subtitle>
                  <Form.Control
                    type="text"
                    placeholder="<selezionare uno o più gruppi>"
                    aria-label="Disabled input example"
                    disabled
                    readOnly
                  />
                  <BsFillTrashFill />
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <Card className="mb-5">
            <Card.Header>
              <Row>
                <Col>
                  <h5 className="m-0 mt-auto page-title text-uppercase ">
                    {t('step 3 - deleghe')} &nbsp;
                    <IoMdInformationCircle />
                  </h5>
                </Col>
                <Col>
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Card.Subtitle>Aggiungi deleghe</Card.Subtitle>
                  <Form.Label>Delegante</Form.Label>
                  <Form.Control
                    type="search"
                    className="autocomplete"
                    placeholder="Inserisci almeno 3 caratteri"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Card.Subtitle>Deleghe confermate</Card.Subtitle>
                  <Form.Label>Delegato</Form.Label>
                  <Form.Control type="text" placeholder="Rossi M. per Scarpa G." />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Delegato</Form.Label>
                  <Form.Control
                    type="search"
                    className="autocomplete"
                    placeholder="Inserisci almeno 3 caratteri"
                  />
                </Form.Group>
                <Col>
                  <Button variant="secondary">Confirm</Button>{' '}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <Card className="mb-5">
            <Card.Header>
              <Row>
                <Col>
                  <h5 className="m-0 mt-auto page-title text-uppercase ">
                    {t('step 4 - documenti')} &nbsp;
                    <IoMdInformationCircle />
                  </h5>
                </Col>
                <Col>
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircle size={13} />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Card.Subtitle>Aggiungi ODG *</Card.Subtitle>
                    <Form.Control type="file" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Card.Subtitle>Altri documenti</Card.Subtitle>
                    <Form.Control type="file" />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Card.Subtitle>File Caricati</Card.Subtitle>
                  <Form.Control
                    type="text"
                    placeholder="ODG"
                    aria-label="Disabled input example"
                    disabled
                    readOnly
                  />
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <Card className="mb-5">
            <Card.Header>
              <Row>
                <Col>
                  <h5 className="m-0 mt-auto page-title text-uppercase ">
                    {t('step 5 - votazioni')} &nbsp;
                    <IoMdInformationCircle />
                  </h5>
                </Col>
                <Col>
                  <BsCircleFill size={13} fill="red" />
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                  <AiOutlineLine size={13} />
                  <BsCircleFill size={13} fill="red"/>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Card.Subtitle>Aggiungi votazione</Card.Subtitle>
                    <Form.Label>Votazioni da effettuare durante l'assemblea</Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Card.Subtitle>Votazioni</Card.Subtitle>
                    <Form.Label>Lista vuota</Form.Label>
                    <Form.Group className="mb-3">
                      <Form.Control placeholder="No items" disabled />
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>

        <Row>
          <Col className="d-flex align-items-end flex-column">
            <button type="button" className="btn-sm btn-success mt-auto">
              {t('Salva Assemblea')}
            </button>
          </Col>
        </Row>
        <hr />
      </Container>
    </Fragment>
  );
};

export default NewConference;
