import { FC, Fragment } from 'react';
import { IGroup, IGroupMember } from '../../interfaces/group.interface';
import Group from './Group';

interface IProps {
  groups: Array<IGroup>;
  readonly?: boolean;
  onToggleMemberPresence?: (group: IGroupMember) => void;
}

const GroupList: FC<IProps> = (props) => (
  <Fragment>
    {props.groups.map((group: IGroup) => (
      <Group group={group} readonly={props.readonly} onToggleMemberPresence={props.onToggleMemberPresence} />
    ))}
  </Fragment>
);

export default GroupList;
