import { JitsiMeeting } from '@jitsi/web-sdk';
import { FC, Fragment } from 'react';
import { IConference } from '../../interfaces/conference.interface';
import { IUser } from '../../interfaces/user.interface';

interface IProps {
  user: IUser;
  conference: IConference;
}

const Jitsi: FC<IProps> = (props) => {
  const handleJitsiIFrameRef = (parentNode: HTMLDivElement) => {
    parentNode.style.width = '100%';
    parentNode.style.height = '100%';
  };

  return (
    <Fragment>
      <JitsiMeeting
        domain="meet.jit.si"
        roomName={`opinio-conference-${props.conference.id_conference}`}
        configOverwrite={{
          disableDeepLinking: true,
          prejoinPageEnabled: false,
          startWithAudioMuted: true,
          startWithVideoMuted: true,
          startScreenSharing: false,
          enableClosePage: true,
          noticeMessage: 'noticeMessage',
        }}
        interfaceConfigOverwrite={{
          MOBILE_APP_PROMO: false,
          OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron', 'safari'],
          UNSUPPORTED_BROWSERS: [],
        }}
        userInfo={{
          email: `${props.user.full_name}@nomail.com`,
          displayName: props.user.full_name,
        }}
        onApiReady={() => {}}
        getIFrameRef={handleJitsiIFrameRef}
      />
    </Fragment>
  );
};

export default Jitsi;
