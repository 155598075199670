import { FC, Fragment } from 'react';
import { Card, Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PageParagraph from '../../components/PageParagraph';
import PageSubtitle from '../../components/PageSubtitle';
import PageTitle from '../../components/PageTitle';

const UserDashboard: FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <PageTitle content={t('user-dashboard.page.title')} />
      <PageSubtitle content={t('user-dashboard.page.subtitle')} />
      <PageParagraph content={t('user-dashboard.page.paragraph')} />
      <Container fluid as="section">
        <Row className="d-flex align-items-stretch">
          <Col xs={12} md={{ span: 4, offset: 4 }} className="">
            <Card className="h-100">
              <Card.Body>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">{t('user-dashboard.card.list-item-1.title')}</h6>
                    <p>{t('user-dashboard.card.list-item-1.paragraph')}</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">{t('user-dashboard.card.list-item-2.title')}</h6>
                    <p>{t('user-dashboard.card.list-item-2.paragraph')}</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <h6 className="text-uppercase text-primary">{t('user-dashboard.card.list-item-3.title')}</h6>
                    <p>{t('user-dashboard.card.list-item-3.paragraph')}</p>
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserDashboard;
