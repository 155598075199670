import { FC, useEffect, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row, Button } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { IMessage } from '../../interfaces/message.interface';

const MessageList: FC = () => {
  const { t } = useTranslation();
  
  const [searchValue, setSearchValue] = useState('');

  const messages: IMessage[] = [
    {
        id: 101,
        date: '16.02.2022',
        subject: 'Nuova conferenza',
        body: 'Prima chiamata 18.02.2022 alle h18'
    },
    {
        id: 102,
        date: '16.02.2022',
        subject: 'Nuova votazione',
        body: 'Partecipa alla votazione durante la conferenza del 18.02.2022 alle h18'
    },
    {
        id: 103,
        date: '17.02.2022',
        subject: 'Nuova votazione',
        body: 'Partecipa alla votazione durante la conferenza del 18.02.2022 alle h18'
    }
  ];

  const rowActions = (_cell: undefined, row: IMessage, _rowIndex: number) => {
    return (
      <div className="d-grid">
        <Button
          size="sm"
          disabled
          onClick={() => {
            //console.debug('rowActions', row);
          }}
        >
          {t('admin-messages.message-list.table.actions.edit')}
        </Button>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'date',
      text: t('admin-messages.message-list.table.columns.date'),
    },
    {
      dataField: 'subject',
      text: t('admin-messages.message-list.table.columns.subject'),
    },
    {
      dataField: 'body',
      text: t('admin-messages.message-list.table.columns.body'),
    },
    {
      dataField: 'edit',
      text: '',
      formatter: rowActions,
    },
  ];

  const options = {
    alwaysShowAllBtns: true,
  };

  return (
    <Card>
      <Card.Header>
        <Form>
          <Row>
            <Col md={{ span: 3 }}>
              <InputGroup>
                <FormControl
                  placeholder={t('admin-messages.message-list.table.actions.search')}
                  value={searchValue}
                  onInput={(e) => setSearchValue(e.currentTarget.value)}
                />
                <InputGroup.Text>
                  <Search />
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col md={{ offset: 9 }}></Col>
          </Row>
        </Form>
      </Card.Header>
      <Card.Body>
        <BootstrapTable
          keyField="id"
          bordered={false}
          data={messages}
          columns={columns}
          pagination={paginationFactory(options)}
        />
      </Card.Body>
    </Card>
  );
};

export default MessageList;
