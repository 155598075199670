import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { UnauthenticatedRoutes } from '../enums/routes.enum';
import { IUser } from '../interfaces/user.interface';

interface IProps {
  user: IUser | null;
  component: FC;
}

export const AuthenticatedRoute: FC<IProps> = ({ user, component: Component }) => {
  return !user ? <Navigate to={UnauthenticatedRoutes.Login} /> : <Component />;
};

export default AuthenticatedRoute;
