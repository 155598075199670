import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '../enums/user-roles.enum';
import { IUser } from '../interfaces/user.interface';
import { RootState } from '../redux/store';
import LayoutAdmin from './LayoutAdmin';
import LayoutUser from './LayoutUser';

interface IProp {
  logo: string;
}

const Layout: FC<IProp> = (props) => {
  const user: IUser | null = useSelector((s: RootState) => (s.auth.user?.value as IUser) || null);

  return (
    <Fragment>
      {user ? (
        <Fragment>
          {
            {
              [UserRole.Admin]: (
                <LayoutAdmin logo={props.logo} user={user}>
                  {props.children}
                </LayoutAdmin>
              ),
              [UserRole.User]: (
                <LayoutUser logo={props.logo} user={user}>
                  {props.children}
                </LayoutUser>
              ),
            }[user.role]
          }
        </Fragment>
      ) : (
        <main className="d-flex no-auth">{props.children}</main>
      )}
    </Fragment>
  );
};

export default Layout;
