import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProfileForm from '../components/ProfileForm';

const Profile: FC = () => {
  const { t } = useTranslation();

  return (
    <Container fluid as="section">
      <Row>
        <Col>
          <h1 className="m-0 mt-auto page-title text-uppercase ">{t('profile.page.title')}</h1>
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <ProfileForm />
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
