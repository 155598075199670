import { FC, Fragment, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthenticatedRoutesUser } from '../../enums/routes.enum';
import { IConference } from '../../interfaces/conference.interface';
import { getConferences } from '../../redux/slices/conferences.slice';
import { AppDispatch, RootState } from '../../redux/store';

const ConferenceList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const conferences: IConference[] = useSelector((s: RootState) => s.conferences.value as IConference[]);

  useEffect(() => {
    dispatch(getConferences());
  }, [dispatch]);

  return (
    <Fragment>
      {conferences.map((x: IConference, index) => (
        <div key={index} className="p-3">
          <h3>{x.title}</h3>
          <p>{x.start_date}</p>
          <Link to={AuthenticatedRoutesUser.Conference.replace(':conferenceId', x.id_conference.toString())}>
            <button className="btn-sm btn-success w-100">{t('user-conferences.actions.details')}</button>
          </Link>
          <hr />
        </div>
      ))}
    </Fragment>
  );
};

export default ConferenceList;
