import {
  Action,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import uiReducer from './slices/ui.slice';
import authReducer from './slices/auth.slice';
import conferencesReducer from './slices/conferences.slice';
import conferenceReducer from './slices/conference.slice';
import groupsReducer from './slices/groups.slice';
import usersReducer from './slices/users.slice';
import userReducer from './slices/user.slice';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    auth: authReducer,
    profile: userReducer,
    conferences: conferencesReducer,
    conference: conferenceReducer,
    groups: groupsReducer,
    users: usersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
