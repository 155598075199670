import { FC, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import { IDocument } from '../../interfaces/document.interface';

interface IProps {
  documents: Array<IDocument>;
  readonly?: boolean;
  onDeleteClick?: (document: IDocument) => void;
}

const DocumentList: FC<IProps> = (props) => {
  const { t } = useTranslation();

  const readonly: boolean = props.readonly !== undefined ? props.readonly : true;

  const handleDelete = (document: IDocument) => {
    props.onDeleteClick && props.onDeleteClick(document);
  };

  return (
    <Fragment>
      {props.documents ? (
        props.documents.map((document: IDocument) => (
          <Fragment>
            <div data-id={document.id.toString()} className="d-flex">
              <div className="flex-grow-1 align-self-center">
                <a className="text-decoration-none" href={document.url} target="_blank" rel="noreferrer">
                  <small className="text-muted">{document.name}</small>
                </a>
              </div>
              <div className="flex-shrink-1 text-end pe-3">
                {!readonly && props.onDeleteClick && (
                  <Button variant="link" className="text-decoration-none" onClick={() => handleDelete(document)}>
                    <FaTrashAlt className="text-muted" />
                  </Button>
                )}
              </div>
            </div>
            <hr className="mt-2" />
          </Fragment>
        ))
      ) : (
        <div className="text-center">
          <em className="text-muted">{t('components.document-list.no-documents')}</em>
          <hr className="mt-2" />
        </div>
      )}
    </Fragment>
  );
};

export default DocumentList;
