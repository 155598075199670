import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IFailResponse, IRejectResponse, IServerResponse, ISuccessResponse } from '../../api/api.interface';
import UsersAPI from '../../api/users.api';
import { IAttendee } from '../../interfaces/attendee.interface';
import { parseErrorToRejectResponse, parseRejectServerResponseToRejectResponse } from '../../utils/api-reject-parser';

export interface IGetUsersFulfilledPayload {
  list: IAttendee[],
}

export const getUsers = createAsyncThunk(
  "users/get",
  async (_thunkPayload: undefined, thunkAPI) => {
    try {
      const response = await UsersAPI.getUsers();

      const payload = response.data as IServerResponse<IAttendee>;
      if (!payload.success) {
        const failPayload = response.data as IFailResponse;
        const rejectValue = parseRejectServerResponseToRejectResponse(failPayload);

        return thunkAPI.rejectWithValue(rejectValue);
      } else {
        const successPayload = response.data as ISuccessResponse<IAttendee>;

        const list = successPayload.data as IAttendee[];

        const fulfillValue = { list } as IGetUsersFulfilledPayload;

        return thunkAPI.fulfillWithValue(fulfillValue);
      }
    } catch (e) {
      const error = e as AxiosError;
      if (!error.isAxiosError) throw e;

      const rejectValue = parseErrorToRejectResponse(error) as IRejectResponse;
      return thunkAPI.rejectWithValue(rejectValue);
    }
  }
);

export interface IUsersState {
  error: IRejectResponse | null;
  value: IAttendee[];
}

const initialState: IUsersState = {
  error: null,
  value: [],
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.error = null;
      state.value = [];
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const payload = action.payload as unknown as IGetUsersFulfilledPayload;

      state.value = payload.list;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      const payload = action.payload as unknown as IRejectResponse;

      state.error = payload;
    });
  },
});

export default usersSlice.reducer;
