import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getGroups = () => {
  return AxiosService.instance.axios.get(ApiEndpoints.GroupList);
};

export const GroupsAPI = {
  getGroups
};

export default GroupsAPI;
