import { FC, Fragment } from 'react';
import { Button, Card, CloseButton, Col, Container, Form, Row } from 'react-bootstrap';
import { MdCancel } from 'react-icons/md';

const UserPollVote: FC = () => {
  return (
    <Fragment>
      <Row>
        <Col></Col>
        <Col xs={10}>
          <Col>
            <Card border="light" style={{ width: '24rem' }}>
              <Card.Header as="h1">
                Give your opinion!{' '}
                <Button className="bg-outline-secondary">
                  <MdCancel size={24} />
                </Button>
                <CloseButton />
              </Card.Header>
              <Card.Body>
                <Card.Title className="mb-4" as="h3">
                  VOTING INFO
                </Card.Title>
                <Card.Subtitle as="h5">Title</Card.Subtitle>
                <Card.Text className="mb-4">Wall color (on floor 1)</Card.Text>

                <Card.Subtitle as="h5">Description</Card.Subtitle>
                <Card.Text className="mb-4">What color do you prefer?</Card.Text>

                <Card.Subtitle as="h5">Option</Card.Subtitle>
                <Card.Text className="mb-4">Min: 1 - Max: 2</Card.Text>

                <Card.Title className="mb-4" as="h3">
                  ANSWERS
                </Card.Title>
                <Form>
                  <Form.Check type="switch" id="custom-switch" label="Orange" />
                </Form>
                <Form>
                  <Form.Check type="switch" id="custom-switch" label="Yellow" />
                </Form>
                <Form>
                  <Form.Check type="switch" id="custom-switch" label="White" />
                </Form>
                <Form>
                  <Form.Check type="switch" id="custom-switch" label="Black" />
                </Form>
                <Form className="mb-4">
                  <Form.Check type="switch" id="custom-switch" label="Pink" />
                </Form>
                <Container fluid>
                  <Row>
                    <Col></Col>
                    <Col xs={10}>
                      <Col>
                        <Button variant="success">Confirm your vote</Button>
                      </Col>
                    </Col>
                    <Col></Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Col>
        <Col></Col>
      </Row>
    </Fragment>
  );
};
export default UserPollVote;
