import { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NotImplemented: FC = () => {
  const { t } = useTranslation();

  return (
    <Container fluid as="section" className="d-flex align-items-center">
      <div className="m-auto text-center">
        <h1 className="text-primary">{t('not-implemented.title')}</h1>
        <h3 className="text-muted">{t('not-implemented.subtitle')}</h3>
      </div>
    </Container>
  );
};

export default NotImplemented;
