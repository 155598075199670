import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IGroup, IGroupMember } from '../../interfaces/group.interface';
import Accordion from './Accordion';
import GroupMember from './GroupMember';

interface IProps {
  group: IGroup;
  readonly?: boolean;
  onToggleMemberPresence?: (group: IGroupMember) => void;
}

const Group: FC<IProps> = (props) => {
  const { t } = useTranslation();

  let [checkedInMembers, setCheckedInMembers] = useState<number[]>([]);

  const readonly: boolean = props.readonly !== undefined ? props.readonly : true;

  const toggleMemberPresemce = (member: IGroupMember) => {
    if (checkedInMembers.some((x) => x === member.id)) {
      setCheckedInMembers([...checkedInMembers.filter((x) => x !== member.id)]);
    } else {
      setCheckedInMembers([...checkedInMembers, member.id]);
    }
  };

  return (
    <Fragment>
      <p className="text-muted text-uppercase">{props.group.nome}</p>
      <p className="mb-2">
        <b className="pe-1">{t('components.group.quotas')}</b>
        <b className="pe-1">:</b>
        <b className="pe-1 text-success">
          {props.group.utentiGruppo.reduce((acc, curr) => (acc += curr.peso || 0), 0).toFixed(3)}
        </b>
        <b className="pe-1 text-success">/</b>
        <b className="pe-1 text-success">{props.group.totalePeso.toFixed(3)}</b>
      </p>
      <div className="d-flex">
        <div className="flex-grow-1 align-self-center">
          <b>
            <span className="pe-1">{t('components.group.checked-in-members')}</span>
            <span className="pe-1">:</span>
            <span className="pe-1">{checkedInMembers.length}</span>
          </b>
        </div>
        <div className="flex-grow-1 align-self-center">
          <b>
            <span className="pe-1">{t('components.group.checked-out-members')}</span>
            <span className="pe-1">:</span>
            <span className="pe-1">{props.group.utentiGruppo.length - checkedInMembers.length}</span>
          </b>
        </div>
      </div>
      <Accordion expanded={true}>
        {props.group.utentiGruppo.map((member: IGroupMember) => (
          <GroupMember
            member={member}
            readonly={false}
            isCheckedIn={checkedInMembers.some((x) => x === member.id)}
            isPresident={member.id === props.group.presidentId}
            isSecretary={member.id === props.group.secretaryId}
            onToggleMemberPresence={toggleMemberPresemce}
          />
        ))}
      </Accordion>
    </Fragment>
  );
};

export default Group;
