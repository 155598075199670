import { FC, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Settings: FC = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="mt-3">
      <Fragment>
        <h4>SETTINGS</h4>
        <h1>Manage your Account</h1>
        <hr />
        <p>Everything you need to manage your account and to customise your Opinio experience.</p>
      </Fragment>
      <Fragment>
        <Row></Row>

        <Row></Row>

        <Row></Row>
      </Fragment>
    </Container>
  );
};

export default Settings;
