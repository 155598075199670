import { FC, Fragment, useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { Routes } from '../../enums/routes.enum';
import { IConference } from '../../interfaces/conference.interface';
import { IUser } from '../../interfaces/user.interface';
import { getConference, IGetConferenceFulfilledPayload } from '../../redux/slices/conference.slice';
import { startLiveConference } from '../../redux/slices/ui.slice';
import { AppDispatch, RootState } from '../../redux/store';
import AuthService from '../../services/auth.service';

const AdminConference: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { conferenceId } = useParams<{ conferenceId: string }>();

  const user: IUser | null = AuthService.instance.user;

  const conference: IConference | null = useSelector((s: RootState) => s.conference.value);

  useEffect(() => {
    if (!conferenceId) return;

    dispatch(getConference({ conferenceId }))
      .unwrap()
      .then((args) => {
        const payload = args as unknown as IGetConferenceFulfilledPayload;
        dispatch(startLiveConference(payload.conference));
      })
      .catch(() => navigate(Routes.NotFound));
  }, [dispatch]);

  return (
    user &&
    conference && (
      <Fragment>
        <PageTitle content={t('admin-conference.page.title')} />
        <Container fluid as="section">
          <Row>
            <Col xs={12} md={4}>
              <Card className="my-3">
                <Card.Title className="p-3 d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <h5 className="text-uppercase text-primary">{t('admin-conference.cards.documents.title')}</h5>
                  </div>
                  <div className="flex-shrink-1">
                    <Button size="sm" disabled>
                      {t('admin-conference.cards.documents.action')}
                    </Button>
                  </div>
                </Card.Title>
                <Card.Body></Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="my-3">
                <Card.Title className="p-3 d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <h5 className="text-uppercase text-primary">{t('admin-conference.cards.polls.title')}</h5>
                  </div>
                  <div className="flex-shrink-1">
                    <Button size="sm" disabled>
                      {t('admin-conference.cards.polls.action')}
                    </Button>
                  </div>
                </Card.Title>
                <Card.Body></Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="my-3">
                <Card.Title className="p-3 d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <h5 className="text-uppercase text-primary">{t('admin-conference.cards.groups.title')}</h5>
                  </div>
                  <div className="flex-shrink-1">
                    <Button size="sm" disabled>
                      {t('admin-conference.cards.groups.action')}
                    </Button>
                  </div>
                </Card.Title>
                <Card.Body></Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  );
};

export default AdminConference;
