import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

interface IProps {
  content: string;
}

const PageSubtitle: FC<IProps> = (props) => (
  <Container fluid as="section">
    <Row className="py-2">
      <Col className="d-flex align-items-start flex-column">
        <h3 className="m-0 mt-auto page-subtitle">{props.content}</h3>
      </Col>
      {props.children && <Col className="d-flex align-items-end flex-column">{props.children}</Col>}
    </Row>
  </Container>
);

export default PageSubtitle;
