import { IGetUserThunkPayload } from '../redux/slices/user.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const getUsers = () => {
  return AxiosService.instance.axios.get(ApiEndpoints.UserList);
};

export const getUser = (payload: IGetUserThunkPayload) => {
  return AxiosService.instance.axios.get(ApiEndpoints.User.replace(':id_user', payload.id.toString()));
};

export const UsersAPI = {
  getUsers,
  getUser
};

export default UsersAPI;
